.about-article,
.career-article {
    @include articleReset(15px, 30px);
    color: #727171;
    p {
        margin-bottom: 0;
    }
    @media screen and (max-width: 1200px) {
        font-size: 14px;
    }
    @media screen and (max-width: 750px) {
        line-height: 28px;
        font-size: 13px;
    }
}

.article-img-mobile {
    display: none;
    @media screen and (max-width: 750px) {
        display: block;
    }
}

.article-img-pc {
    @media screen and (max-width: 750px) {
        display: none;
    }
}

.about-article {
    &-mobile {
        display: none;
    }
    &-box {
        padding: 120px 0;
        &:nth-child(even) {
            background-color: #efeff0;
        }
    }
    .p {
        margin-bottom: 50px;
        text-align: center;
    }
    .img {
        text-align: center;
    }
    .video-link {
        margin-bottom: 120px;
    }
    @media screen and (max-width: 1200px) {
        &-box {
            padding: 100px 0;
        }
        .p {
            margin-bottom: 40px;
        }
        .video-link {
            margin-bottom: 100px;
        }
    }
    @media screen and (max-width: 1024px) {
        &-box {
            padding: 80px 0;
        }
        .p {
            margin-bottom: 32px;
        }
        .video-link {
            margin-bottom: 80px;
        }
    }
    @media screen and (max-width: 750px) {
        &-pc {
            display: none;
        }
        &-mobile {
            display: block;
        }
        &-box {
            padding: px2rem(110px) 0;
        }
        .p {
            margin-bottom: 20px;
            text-align: left;
        }
        .video-link {
            margin-bottom: px2rem(110px);
        }
    }
}

.about-company-title {
    margin-bottom: 25px;
    text-align: center;
    .logo {
        margin-bottom: 10px;
        img {
            &:nth-of-type(2) {
                display: none;
            }
        }
    }
    h3 {
        font-size: 30px;
        line-height: 50px;
        color: #323232;
    }
    p {
        line-height: 1.2;
        font-size: 14px;
        color: #464646;
        font-family: 'GothamExLight';
    }
    @media screen and (max-width: 1024px) {
        .logo {
            margin-bottom: 6px;
            img {
                max-width: px2rem(312px);
                &:nth-of-type(1) {
                    display: none;
                }
                &:nth-of-type(2) {
                    display: inline-block;
                }
            }
        }
        h3 {
            font-size: 23px;
        }
        p {
            font-size: 13px;
        }
    }
    @media screen and (max-width: 750px) {
        margin-bottom: 20px;
        .logo {
            margin-bottom: 4px;
            img {
                max-width: px2rem(312px);
                &:nth-of-type(1) {
                    display: none;
                }
                &:nth-of-type(2) {
                    display: inline-block;
                }
            }
        }
        h3 {
            font-size: 20px;
        }
        p {
            font-size: 10px;
        }
    }
}

.img-group2 {
    display: flex;
    text-align: center;
    li {
        flex: 1;
        min-width: 0;
        list-style: none !important;
        & + li {
            margin-left: 40px;
        }
        p {
            margin-top: 9px;
        }
    }
    @media screen and (max-width: 750px) {
        display: block;
        li {
            & + li {
                margin: 20px 0 0;
            }
        }
    }
}

.video-link {
    position: relative;
    cursor: pointer;
    &:hover {
        .video-link-icon {
            border-color: $baseColor;
            background-color: rgba($baseColor, .4);
        }
    }
    img {
        display: block;
        width: 100%;
        height: auto;
    }
    &-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 65px;
        height: 65px;
        line-height: 63px;
        text-align: center;
        background-color: rgba(0, 0, 0, .2);
        border: 1px solid #fff;
        border-radius: 50%;
        transition: all .3s;
        &:before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            margin-left: 10px;
            border-style: solid;
            border-color: transparent transparent transparent #fff;
            border-width: 13px 0 13px 20px;
            transition: all .3s;
        }
    }
    @media screen and (max-width: 750px) {
        &-icon {
            width: 46px;
            height: 46px;
            line-height: 44px;
            &:before {
                margin-left: 6px;
                border-width: 9px 0 9px 12px;
            }
        }
    }
}

.video-popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9001;
    &.show {
        .video-popup-box,
        .video-popup-mask {
            opacity: 1;
        }
        .video-popup-box {
            transform: translate(-50%, -50%) scale(1);
        }
    }
    &-box {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 1340px;
        background-color: #000;
        box-shadow: 0 6px 20px rgba(0, 0, 0, .8);
        opacity: 0;
        z-index: 6;
        transform: translate(-50%, -50%) scale(.8);
        transition: all .4s;
    }
    .video {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: (638 / 1340) * 100%;
        overflow: hidden;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100% !important;
            height: 100% !important;
            overflow: hidden;
        }
    }
    &-mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .9);
        z-index: 2;
        opacity: 0;
        transition: all .4s;
    }
    &-close {
        position: absolute;
        top: 20px;
        right: 20px;
        color: $baseColor;
        font-size: 24px;
        cursor: pointer;
        transition: all .3s;
        z-index: 9;
        &:hover {
            transform: rotate(180deg);
        }
    }
    @media screen and (max-width: 1440px) {
        &-box {
            width: calc(100% - 100px);
        }
    }
    @media screen and (max-width: 750px) {
        &.show {
            .video-popup-box {
                transform: scale(1);
            }
        }
        &-box {
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            transform: scale(.8);
        }
        .video {
            padding-bottom: 60%;
            @media screen and (max-width: 1024px) {
                padding-bottom: 160%;
            }
        }
        &-close {
            top: px2rem(40px);
            right: px2rem(40px);
            font-size: px2rem(40px);
        }
    }
}

.about-culture-wp {
    padding: 130px 0;
    @media screen and (max-width: 1200px) {
        padding: 100px 0;
    }
    @media screen and (max-width: 1024px) {
        padding: 70px 0;
    }
    @media screen and (max-width: 750px) {
        padding: px2rem(100px) 0;
    }
}

.about-culture-box {
    font-size: 14px;
    line-height: 28px;
    color: #787878;
    .p-margin-bt24 {
        margin-bottom: 24px;
    }
    & + .about-culture-box {
        margin-top: 130px;
    }
    @media screen and (max-width: 1200px) {
        & + .about-culture-box {
            margin-top: 90px;
        }
    }
    @media screen and (max-width: 1024px) {
        & + .about-culture-box {
            margin-top: 70px;
        }
    }
    @media screen and (max-width: 750px) {
        font-size: 13px;
        line-height: 26px;
        & + .about-culture-box {
            margin-top: px2rem(100px);
        }
    }
}

.about-culture-title {
    position: relative;
    margin-bottom: 65px;
    padding-bottom: 40px;
    color: $baseColor;
    h3 {
        font-family: 'Gotham-Bold';
        font-size: 40px;
        letter-spacing: -1px;
    }
    h4 {
        margin-top: 10px;
        font-size: 28px;
    }
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 45px;
        height: 1px;
        background-color: #898989;
    }
    @media screen and (max-width: 1200px) {
        margin-bottom: 55px;
        padding-bottom: 35px;
        h3 {
            font-size: 36px;
        }
        h4 {
            font-size: 24px;
        }
    }
    @media screen and (max-width: 1024px) {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        padding-bottom: 0;
        text-align: center;
        color: #000;
        h3 {
            order: 2;
            margin-top: 2px;
            line-height: 1.2;
            font-size: 13px;
            font-family: 'GothamExLight';
            letter-spacing: 0;
        }
        h4 {
            margin-top: 0;
        }
        &:after {
            display: none;
        }
    }
    @media screen and (max-width: 750px) {
        margin-bottom: 25px;
        h3 {
            font-size: 12px;
        }
        h4 {
            font-size: 20px;
        }
    }
}

.about-culture-content {
    display: flex;
    .info {
        margin-right: 70px;
        width: 50%;
        li {
            list-style: none !important;
            & + li {
                margin-top: 50px;
            }
        }
        &-title {
            margin-bottom: 4px;
            font-size: 20px;
            font-weight: bold;
            color: #727171;
        }
    }
    .img {
        flex: 1;
        min-width: 0;
        order: 2;
        img {
            display: block;
            width: 100%;
            & + img {
                margin-top: 50px;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        .info {
            margin-right: 50px;
            li {
                & + li {
                    margin-top: 40px;
                }
            }
            &-title {
                font-size: 18px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        display: block;
        .info {
            margin: 50px 0 0;
            width: 100%;
            li {
                & + li {
                    margin-top: 32px;
                }
            }
        }
        .img {
            img {
                & + img {
                    margin-top: px2rem(30px);
                }
            }
        }
    }
    @media screen and (max-width: 750px) {
        .info {
            margin-top: 30px;
            li {
                & + li {
                    margin-top: 20px;
                }
            }
            &-title {
                font-size: 16px;
            }
        }
    }
}

.about-culture-list {
    display: flex;
    .box-wrap {
        width: 50%;
        box-sizing: border-box;
        &:first-child {
            padding-right: 45px;
        }
        &:last-child {
            padding-left: 75px;
        }
    }
    li {
        display: flex;
        list-style: none !important;
        & + li {
            margin-top: 42px;
        }
    }
    .icon {
        margin-right: 8px;
        width: 30px;
        line-height: 26px;
        text-align: center;
        img {
            display: inline-block;
            vertical-align: middle;
        }
    }
    .info {
        flex: 1;
        min-width: 0;
        &-title {
            margin-bottom: 4px;
            font-size: 20px;
            color: #727171;
            font-weight: bold;
        }
    }
    @media screen and (max-width: 1440px) {
        .box-wrap {
            &:first-child {
                padding-right: 40px;
            }
            &:last-child {
                padding-left: 65px;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        .box-wrap {
            &:first-child {
                padding-right: 30px;
            }
            &:last-child {
                padding-left: 40px;
            }
        }
        li {
            & + li {
                margin-top: 32px;
            }
        }
        .icon {
            width: 26px;
        }
        .info {
            &-title {
                font-size: 18px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        display: block;
        .box-wrap {
            width: 100%;
            &:first-child {
                padding-right: 0;
            }
            &:last-child {
                margin-top: 32px;
                padding-left: 0;
            }
        }
    }
    @media screen and (max-width: 750px) {
        .box-wrap {
            &:last-child {
                margin-top: 20px;
            }
        }
        li {
            & + li {
                margin-top: 20px;
            }
        }
        .icon {
            line-height: 25px;
            width: 18px;
        }
        .info {
            &-title {
                font-size: 16px;
            }
        }
    }
}

.chairman-box {
    padding: 110px 0 140px;
    text-align: center;
    .img {
        text-align: center;
        margin-bottom: 80px;
    }
    @media screen and (max-width: 1200px) {
        padding: 90px 0 100px;
        .img {
            margin-bottom: 70px;
        }
    }
    @media screen and (max-width: 1024px) {
        padding: 70px 0 80px;
        .img {
            margin-bottom: 50px;
        }
    }
    @media screen and (max-width: 750px) {
        padding: px2rem(100px) 0;
        text-align: left;
        .img {
            margin-bottom: 35px;
        }
    }
}

.chairman-title,
.subbrand-title {
    text-align: center;
    .title {
        font-size: 30px;
        color: #000;
    }
    p {
        font-size: 14px;
        color: #221815;
    }
    @media screen and (max-width: 1200px) {
        .title {
            font-size: 26px;
        }
        p {
            font-size: 13px;
        }
    }
    @media screen and (max-width: 1024px) {
        .title {
            font-size: 24px;
        }
    }
    @media screen and (max-width: 750px) {
        .title {
            font-size: 20px;
        }
        p {
            line-height: 1.5;
            font-size: 10px;
        }
    }
}

.chairman-title {
    margin-bottom: 50px;
    @media screen and (max-width: 1200px) {
        margin-bottom: 40px;
    }
    @media screen and (max-width: 1024px) {
        margin-bottom: 30px;
    }
    @media screen and (max-width: 750px) {
        margin-bottom: 20px;
    }
}

.chairman-wish {
    margin: 50px auto 0;
    max-width: 750px;
    text-align: center;
    font-size: 16px;
    line-height: 30px;
    color: #595757;
    font-weight: bold;
    overflow: hidden;
    &-bottom {
        margin-top: 60px;
        text-align: right;
        font-size: 15px;
        color: #7d7d7d;
    }
    .img-signature {
        margin-top: 6px;
        img {
            margin-right: -95px;
        }
    }
    @media screen and (max-width: 1024px) {
        margin-top: 40px;
        font-size: 15px;
        &-bottom {
            margin-top: 45px;
            font-size: 14px;
        }
        .img-signature {
            img {
                margin-right: px2rem(-30px);
                max-width: 240px;
            }
        }
    }
    @media screen and (max-width: 750px) {
        margin-top: 25px;
        font-size: 14px;
        line-height: 28px;
        &-bottom {
            margin-top: 25px;
            font-size: 13px;
        }
        .img-signature {
            margin-top: 0;
            img {
                max-width: 170px;
            }
        }
    }
}

.about-title {
    margin-bottom: 65px;
    text-align: center;
    h2 {
        font-size: 28px;
        color: #000;
    }
    p {
        line-height: 1.2;
        font-size: 14px;
        color: #828282;
        font-family: 'GothamExLight';
    }
    @media screen and (max-width: 1200px) {
        margin-bottom: 50px;
        h2 {
            font-size: 24px;
        }
        p {
            font-size: 13px;
        }
    }
    @media screen and (max-width: 1024px) {
        margin-bottom: 40px;
    }
    @media screen and (max-width: 750px) {
        margin-bottom: 25px;
        h2 {
            font-size: 20px;
        }
        p {
            font-size: 10px;
        }
    }
}

.history-list {
    li {
        position: relative;
        margin-bottom: px2rem(15px);
        max-height: 112px;
        overflow: hidden;
        background-color: #eee;
        transition: background .3s;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(180deg, transparent, rgba(250, 250, 250, .68));
            z-index: 2;
            transition: opacity .3s;
        }
        &:first-child {
            max-height: none;
        }
        &.active,
        &:hover {
            background-color: #696969;
            &:before {
                opacity: 0;
                z-index: 0;
            }
            .info,
            .year {
                color: #fff;
            }
        }
    }
    .box {
        position: relative;
        display: flex;
        padding: 36px 55px;
        z-index: 1;
    }
    .year {
        padding: 2px 40px 0 0;
        width: 170px;
        line-height: 1;
        font-size: 42px;
        color: #c8c8c8;
        font-family: 'GothamExLight';
        transition: all .3s;
    }
    .info {
        flex: 1;
        min-width: 0;
        line-height: 36px;
        font-size: 15px;
        overflow: hidden;
        color: #bebebe;
        transition: all .3s;
    }
    @media screen and (max-width: 1200px) {
        .year {
            width: 150px;
            font-size: 38px;
        }
        .info {
            line-height: 32px;
            font-size: 14px;
        }
    }
    @media screen and (max-width: 1024px) {
        .box {
            padding: 24px 40px;
        }
        .year {
            padding-right: 30px;
            width: 130px;
            font-size: 34px;
        }
    }
    @media screen and (max-width: 750px) {
        li {
            &.active {
                .year:after {
                    transform: translateY(-50%) rotate(-90deg);
                }
            }
            &:not(.active):hover {
                background-color: #eee;
                &:before {
                    opacity: 1;
                }
                .info {
                    color: #bebebe;
                }
                .year {
                    color: #c8c8c8;
                }
            }
        }
        .box {
            display: block;
            padding: px2rem(40px) px2rem(30px) px2rem(50px);
        }
        .year {
            position: relative;
            margin-bottom: px2rem(22px);
            padding-left: 12px;
            width: 100%;
            font-size: 24px;
            box-sizing: border-box;
            &:after {
                content: '\e773';
                position: absolute;
                top: 50%;
                right: px2rem(10px);
                font-family: 'iconfont';
                font-size: 16px;
                color: #b6b6b7;
                transform: translateY(-50%) rotate(90deg);
                transition: all .3s;
            }
        }
        .info {
            line-height: 25px;
            font-size: 13px;
            p {
                position: relative;
                padding-left: 12px;
                &:before {
                    content: '';
                    position: absolute;
                    top: 11px;
                    left: 0;
                    width: 3px;
                    height: 3px;
                    background-color: #fff;
                    border-radius: 50%;
                }
            }
        }
    }
}

.honor-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -28px -15px;
    li {
        padding: 0 28px 120px;
        width: (100% / 3);
        box-sizing: border-box;
        &.active {
            .box {
                cursor: pointer;
                &:hover {
                    box-shadow: 8px 8px 16px #efefef;
                    .box-hover {
                        display: block;
                    }
                }
            }
        }
    }
    .box {
        position: relative;
        padding: 50px 50px 46px;
        text-align: center;
        height: 100%;
        background-color: #fff;
        box-sizing: border-box;
        overflow: hidden;
        transition: all .3s;
        * {
            pointer-events: none;
        }
        &-hover {
            display: none;
            position: absolute;
            margin: -12px 0 0 -12px;
            width: 22px;
            height: 22px;
            border: 1px solid #ddd;
            border-radius: 50%;
            z-index: 2;
            animation: hoverScale 1.5s ease-in-out infinite;
            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                margin: -6px 0 0 -6px;
                width: 10px;
                height: 10px;
                border: 1px solid #ddd;
                border-radius: 50%;
            }
        }
    }
    .content {
        display: none;
    }
    .icon {
        height: 135px;
        line-height: 135px;
        img {
            display: inline-block;
            vertical-align: middle;
            max-height: 100%;
            max-width: 100%;
        }
    }
    .title {
        margin-top: 16px;
        line-height: 1.6;
        font-size: 15px;
        color: #7d7d7d;
    }
    .more {
        display: none;
        margin-top: 10px;
        padding: 0 px2rem(30px);
        height: 24px;
        line-height: 24px;
        font-size: 12px;
        color: $baseColor;
        border: 1px solid $baseColor;
        font-family: 'Gotham-Medium';
        border-radius: 24px;
    }
    @media screen and (max-width: 1440px) {
        margin: 0 -20px -15px;
        li {
            padding: 0 20px 90px;
        }
        .box {
            padding: 40px;
        }
        .icon {
            height: 110px;
            line-height: 110px;
        }
    }
    @media screen and (max-width: 1200px) {
        margin: 0 -15px;
        li {
            padding: 0 15px 70px;
        }
        .box {
            padding: 30px;
        }
        .title {
            font-size: 14px;
        }
        .icon {
            height: 100px;
            line-height: 100px;
        }
    }
    @media screen and (max-width: 1024px) {
        li {
            width: 50%;
        }
    }
    @media screen and (max-width: 750px) {
        margin: 0 px2rem(-30px) px2rem(70px);
        li {
            padding: px2rem(40px) px2rem(30px);
        }
        .box {
            padding: 0;
            box-shadow: none;
        }
        .icon {
            height: 80px;
            line-height: 80px;
        }
        .title {
            margin-top: 10px;
        }
        .more {
            display: inline-block;
        }
    }
    @media screen and (max-width: 414px) {
        .title {
            height: 15px * 3.2;
            overflow: hidden;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            word-break: break-word;
            transition: all .4s;
        }
    }
}

@keyframes hoverScale {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    50% {
        opacity: .6;
        transform: scale(1.3);
    }
    90% {
        opacity: 0;
    }
}


.honor-article {
    @include articleReset(16px, 30px);
    color: #7d7d7d;
    p {
        margin-bottom: 0;
    }
    &-box {
        margin-bottom: 80px;
        padding: 40px 40px 35px;
        text-align: center;
        font-size: 15px;
        box-shadow: 8px 8px 16px #efefef;
        p {
            margin-top: 8px;
        }
    }
    @media screen and (max-width: 1440px) {
        &-box {
            margin-bottom: 65px;
        }
    }
    @media screen and (max-width: 1200px) {
        font-size: 14px;
        &-box {
            margin-bottom: 50px;
            padding: 30px;
            font-size: inherit;
        }
    }
    @media screen and (max-width: 750px) {
        line-height: 28px;
        font-size: 13px;
        &-box {
            margin-bottom: 20px;
            padding: 0;
            box-shadow: none;
            p {
                margin-top: 5px;
            }
        }
    }
}

// 弹框
.honor-popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    z-index: 9000;
    &.show {
        display: flex;
    }
    &.show-ani {
        .honor-popup-box,
        .honor-popup-mask {
            opacity: 1;
        }
        .honor-popup-box {
            transition-delay: .2s;
        }
        .honor-popup-mask {
            transition-delay: 0s;
        }
    }
    &-mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .9);
        opacity: 0;
        z-index: 1;
        transition: all .3s .2s;
    }
    &-box {
        position: relative;
        width: 1340px;
        padding: 120px 40px 110px 70px;
        background-color: #555;
        box-sizing: border-box;
        opacity: 0;
        z-index: 6;
        transition: all .4s;
    }
    .scroller {
        position: relative;
        width: 100%;
        max-height: calc(100vh - 300px);
        overflow: hidden;
    }
    .bscroll-vertical-scrollbar {
        width: 5px !important;
        border-radius: 5px;
        background-color: #313131;
        z-index: 887 !important;
    }
    .bscroll-indicator {
        background-color: $baseColor !important;
        border-color: $baseColor !important;
    }
    &-content {
        display: flex;
        padding: 50px 30px 0 0;
    }
    .box-left {
        margin: 35px 80px 0 0;
        width: 360px;
    }
    .box-right {
        flex: 1;
        min-width: 0;
        img {
            display: block;
            width: 100%;
        }
    }
    .icon {
        img {
            max-width: 100%;
            max-height: 130px;
        }
        &:after {
            content: '';
            display: block;
            margin-top: 28px;
            width: 40px;
            height: 1px;
            background-color: #c9c9c9;
        }
    }
    .title {
        margin: 50px 0 15px;
        line-height: 1.5;
        font-size: 26px;
        color: #fff;
    }
    .content {
        line-height: 26px;
        font-size: 15px;
        color: #dcdddd;
    }
    &-close {
        position: absolute;
        top: 30px;
        right: 40px;
        font-size: 30px;
        color: $baseColor;
        z-index: 1;
        cursor: pointer;
        transition: all .3s;
        &:hover {
            transform: rotate(180deg);
        }
    }
    @media screen and (max-width: 1440px) {
        &-box {
            width: 1200px;
            padding: 80px 30px 70px 50px;
        }
        .scroller {
            max-height: calc(100vh - 300px);
        }
        &-content {
            padding: 30px 20px 0 0;
        }
        .box-left {
            margin: 25px 60px 0 0;
            width: 320px;
        }
        .icon {
            img {
                max-height: 110px;
            }
        }
        .title {
            margin: 40px 0 12px;
            font-size: 22px;
        }
        .content {
            font-size: 14px;
        }
        &-close {
            right: 30px;
        }
    }
    @media screen and (max-width: 1260px) {
        &-box {
            width: calc(100% - .3rem);
        }
        .scroller {
            max-height: calc(100vh - 300px);
        }
        &-content {
            padding: 40px 20px 0 0;
        }
        .box-left {
            margin: 15px 50px 0 0;
            width: 300px;
        }
        .icon {
            img {
                max-height: 90px;
            }
        }
        .title {
            margin-top: 35px;
            font-size: 20px;
        }
        .content {
            line-height: 24px;
            font-size: 13px;
        }
    }
    @media screen and (max-width: 1024px) {
        &-box {
            padding: px2rem(110px) px2rem(25px) px2rem(100px) px2rem(50px);
        }
        .scroller {
            max-height: calc(100vh - 2rem);
        }
        &-content {
            display: block;
            padding: px2rem(60px) px2rem(25px) 0 0;
        }
        .box-left {
            margin: 0 0 35px;
            width: 100%;
        }
        &-close {
            right: px2rem(50px);
            top: px2rem(60px);
        }
    }
    @media screen and (max-width: 750px) {
        .bscroll-vertical-scrollbar {
            width: 3px !important;
        }
        .icon {
            &:after {
                margin-top: 24px;
            }
        }
        .box-left {
            margin-bottom: 18px;
        }
        .title {
            margin-bottom: 8px;
            font-size: 18px;
        }
        &-close {
            font-size: 22px;
        }
    }
}
.cooperation-box{
    width: 100%;
    margin-top: px2rem(80px);
    h3{
        font-weight: bold;
    }
   
    .from-box{
        margin: px2rem(50px) 0;

    }
    .p{
        text-align: center;
        font-size: 15px;
        color: #3c3c3c;
    }
    .ly{
        height: 150px;
    }
    .select-gs{
        opacity: 1 !important;
        width: 100%;
        height: 100%;
        border: none;
        background: transparent;
        outline: none;
        .option-box{
            line-height: 50px !important;
            outline: none;
            border: none !important;
            background-color: transparent !important;
        }
    }
}
.contact-box{
    margin: 10px 0 px2rem(50px);
    line-height: px2rem(50px);
    font-weight: bold;
    .contact-top{
        display: flex;
        align-items: center;
        font-size: 17px;
        font-weight: bold;
        .icon{
            width: 24px;
            margin-right: 10px;
            margin-top: 15px;
            line-height: 2;
            img{
                object-fit: cover;
            }
        }
    }
    .contact-dese{
        font-size: 15px;
        font-weight: normal;
    }
}
.img1{
    margin: 60px 0 50px;
}



