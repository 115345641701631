.logo-footer {
    margin-bottom: 54px;
    text-align: center;
    img {
        max-height: 100%;
        max-width: 100%;
    }
    &-img {
        height: 35px;
    }
    &-bottom {
        margin-top: 22px;
        height: 27px;
    }
    @media screen and (max-width: 1024px) {
        margin-bottom: 30px;
        &-img {
            height: 30px;
        }
        &-bottom {
            margin-top: 18px;
            height: 24px;
        }
    }
    @media screen and (max-width: 750px) {
        margin-bottom: px2rem(15px);
        &-img {
            height: px2rem(35px);
        }
        &-bottom {
            margin-top: px2rem(35px);
            height: 18px;
        }
    }
}


.footer {
    background-color: #181818;
    &-icon-con {
        display: flex;
        padding: 40px 0 50px;
        border-bottom: 1px solid #595959;
        justify-content: space-between;
        width: 100%;
    }
    &-icon-dt {
        font-size: 18px;
        color: #fff;
        margin-bottom: 40px;
        &:after {
            width: 2px;
            height: 16px;
            display: inline-block;
            vertical-align: middle;
            background: $baseColor;
            content: '';
            margin-left: 10px;
            margin-top: -4px;
        }
    }
    &-top {
        padding: 50px 0;
        text-align: center;
        line-height: 28px;
        font-size: 14px;
        color: #b5b5b6;
        font-family: 'Gotham-book';
        border-bottom: 1px solid #595959;
        strong {
            font-family: 'Gotham-Bold';
        }
        &-logo {
            margin-top: 12px;
            height: 27px;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
    &-share {
        display: flex;
        align-items: center;
        & > li {
            position: relative;
            margin-right: 40px;
            //width: 200px;
            //max-width: 25%;
            &:hover {
                .icon {
                    color: #191919;
                    background-color: #fff;
                    &-default {
                        display: none;
                    }
                    &-hover {
                        display: block;
                    }
                }
                .a-box {
                    opacity: .9;
                }
                .a-box-popup {
                    display: flex;
                }
            }
        }
        .icon {
            margin: 0 auto;
            width: 65px;
            height: 65px;
            line-height: 65px;
            font-size: 30px;
            color: #fff;
            border-radius: 50%;
            transition: all .3s;
            img {
                width: 100%;
                height: 100%;
            }
            &-default {
                display: block;
            }
            &-hover {
                display: none;
            }
        }
    }
    .a-box {
        display: block;
        text-align: center;
        //padding: 16px;
        cursor: pointer;
        p {
            margin-top: 20px;
            font-size: 15px;
            color: #fff;
        }
        > img {
            height: 30px;
        }
        &-popup {
            display: none;
            position: absolute;
            top: 110%;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
            padding: 0 6px;
            background-color: #fff;
            box-shadow: 0 0 20px rgba(0, 0, 0, .1);
            z-index: 99;
            &:before {
                content: '';
                position: absolute;
                bottom: 100%;
                left: 50%;
                margin-left: -6px;
                border-style: solid;
                border-width: 0 6px 6px;
                border-color: transparent transparent #fff;
            }
            li {
                padding: 18px 5px 10px;
                width: 150px;
            }
            a {
                display: block;
                &:hover {
                    p {
                        color: $baseColor;
                    }
                }
            }
            p {
                margin-top: 6px;
                font-size: 14px;
                color: #727171;
                transition: all .3s;
            }
            .img {
                img {
                    max-width: 100%;
                    height: auto;
                }
            }
        }
    }
    &-nav {
        display: flex;
        justify-content: space-between;
        padding: 50px 0;
        line-height: 1.5;
        dl {
            min-width: 120px;
            margin-right: 50px;
            &:last-child {
                margin-right: 0;
                min-width: 0;
            }
        }
        dt {
            margin-bottom: 5px;
            a {
                font-size: 16px;
                color: #a5a5a5;
                font-weight: bold;
                transition: all .3s;
                &:hover {
                    color: #fff;
                }
            }
        }
        dd {
            padding-top: 10px;
            a {
                font-size: 15px;
                color: #7f7e7e;
                transition: all .3s;
                &:hover {
                    color: #fff;
                }
            }
        }
    }
    &-content {
        padding: 28px 0;
        background-color: #303030;
        .wp1340 {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }
        &-left {
            line-height: 28px;
            font-size: 16px;
            color: #fff;
            .logo {
                display: inline-block;
                margin-right: 20px;
                max-height: 28px;
                width: auto;
            }
            .tel {
                font-size: 22px;
                font-family: 'Gotham-Medium';
            }
            & + .footer-content-right {
                margin-left: 80px;
                text-align: right;
            }
        }
        &-right {
            flex: 1;
            min-width: 0;
            line-height: 28px;
            font-size: 15px;
            color: #b4b4b4;
            & + .footer-content-left {
                margin-left: 80px;
            }
            a {
                color: #b4b4b4;
                transition: all .3s;
                &:hover {
                    color: #fff;
                }
            }
            .link {
                a {
                    position: relative;
                    display: inline-block;
                    vertical-align: top;
                    padding: 0 15px;
                    &:last-child {
                        padding-right: 0;
                    }
                    &:not(:last-child):after {
                        content: '';
                        position: absolute;
                        right: 0;
                        top: 50%;
                        margin-top: -6px;
                        width: 1px;
                        height: 11px;
                        background-color: #b4b4b4;
                    }
                }
                .a-box-popup {
                    top: auto;
                    bottom: 115%;
                    &:before {
                        bottom: auto;
                        top: 100%;
                        margin-left: -6px;
                        border-width: 6px 6px 0;
                        border-color: #fff transparent transparent;
                    }
                }
                .a-box.on {
                    .a-box-popup {
                        display: flex;
                    }
                }
            }
        }
        .a-box-popup-mask-pc {
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: none;
        }
    }
    &-introduction {
        padding: 100px 0 95px;
        text-align: center;
        .p {
            line-height: 28px;
            font-size: 14px;
            color: #b6b6b6;
            opacity: .7;
            p {
                margin-bottom: 30px;
            }
        }
        .en {
            font-family: 'GothamExLight';
        }
    }
    @media screen and (max-width: 1200px) {
        &-nav {
            dl {
                min-width: 110px;
                margin-right: 30px;
            }
            dt {
                a {
                    font-size: 15px;
                }
            }
            dd {
                a {
                    font-size: 14px;
                }
            }
        }
        &-content {
            &-left {
                line-height: 26px;
                font-size: 15px;
                .logo {
                    margin-right: 15px;
                    max-height: 22px;
                }
                .tel {
                    font-size: 20px;
                }
                & + .footer-content-right {
                    margin-left: 50px;
                }
            }
            &-right {
                line-height: 26px;
                font-size: 14px;
                & + .footer-content-left {
                    margin-left: 50px;
                }
            }
        }
        &-introduction {
            padding: 90px 0 80px;
        }
    }
    @media screen and (max-width: 1024px) {
        display: none;
    }
}

.footer-mobile {
    display: none;
    text-align: center;
    background-color: #181818;
    &-wp {
        padding: 0 px2rem(40px);
    }
    &-introduction {
        padding: px2rem(100px) 0 px2rem(75px);
        .logo {
            margin-bottom: 20px;
        }
        .p {
            line-height: 28px;
            font-size: 15px;
            color: #b5b5b6;
            font-family: 'GothamExLight';
        }
    }
    &-content {
        padding: px2rem(65px) px2rem(30px) px2rem(100px);
        line-height: 26px;
        font-size: 14px;
        color: #b5b5b6;
        background-color: #181818;
        a {
            color: #b5b5b6;
            &:hover {
                text-decoration: underline;
            }
        }
        .tel {
            margin-bottom: 8px;
            font-size: 24px;
            color: #fff;
            font-family: 'Gotham-Medium';
            a {
                color: #fff;
            }
            .icon {
                margin-right: 4px;
                font-size: 22px;
            }
        }
    }
    &-share {
        display: flex;
        justify-content: space-between;
        padding: px2rem(30px) 0 px2rem(50px);
        border-top: 1px dashed #595959;
        border-bottom: 1px dashed #595959;
        & > li {
            position: relative;
            width: 150px;
            max-width: 25%;
            &:hover {
                .icon {
                    color: #191919;
                    background-color: #fff;
                    &-default {
                        display: none;
                    }
                    &-hover {
                        display: block;
                    }
                }
            }
        }
        .a-box {
            display: block;
            text-align: center;
            padding: 12px;
            cursor: pointer;
            p {
                margin-top: 12px;
                font-size: 15px;
                color: #fff;
            }
            &-popup {
                display: none;
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 2000;
                &.show {
                    .a-box-popup-wp,
                    .a-box-popup-mask {
                        opacity: 1;
                    }
                    .a-box-popup-wp {
                        transform: translate(-50%, -50%) scale(1);
                    }
                }
                &-mask {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 2;
                    opacity: 0;
                    transition: all .4s;
                }
                &-wp {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    opacity: 0;
                    transform: translate(-50%, -50%) scale(.8);
                    display: flex;
                    padding: 40px 12px 16px;
                    background-color: #fff;
                    box-shadow: 0 px2rem(4px) px2rem(16px) rgba(0, 0, 0, .2);
                    z-index: 6;
                    transition: all .4s;
                }
                .close-btn {
                    position: absolute;
                    right: 16px;
                    top: 15px;
                    color: #b5b5b5;
                    font-size: 14px;
                }
                .item {
                    padding: 0 8px;
                    width: 100px;
                }
                a {
                    display: block;
                    &:hover {
                        p {
                            color: $baseColor;
                        }
                    }
                }
                p {
                    margin-top: 6px;
                    font-size: 14px;
                    color: #727171;
                    transition: all .3s;
                }
                .img {
                    img {
                        max-width: 100%;
                        height: auto;
                    }
                }
            }
        }
        .icon {
            margin: 0 auto;
            width: 65px;
            height: 65px;
            line-height: 65px;
            font-size: 30px;
            color: #fff;
            border-radius: 50%;
            transition: all .3s;
            img {
                width: 100%;
                height: 100%;
            }
            &-default {
                display: block;
            }
            &-hover {
                display: none;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        display: block;
    }
    @media screen and (max-width: 750px) {
        &-introduction {
            .logo {
                margin-bottom: 16px;
            }
            .p {
                line-height: px2rem(30px);
                font-size: px2rem(18px);
            }
        }
        &-content {
            line-height: 24px;
            font-size: 13px;
            .tel {
                margin-bottom: 6px;
                font-size: 20px;
                .icon {
                    font-size: 18px;
                }
            }
        }
        &-share {
            & > li {
                width: 100px;
            }
            .a-box {
                padding: 5px;
                p {
                    margin-top: 4px;
                    font-size: 13px;
                }
                &-popup {
                    &-wp {
                        padding: 30px 10px 12px;
                    }
                    .close-btn {
                        right: 14px;
                        top: 10px;
                        font-size: 13px;
                    }
                    .item {
                        padding: 0 5px;
                        width: 90px;
                    }
                    p {
                        font-size: 12px;
                    }
                }
            }
            .icon {
                width: 40px;
                height: 40px;
                line-height: 40px;
                font-size: 22px;
                img {
                    // transform: translateY(-5%);
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.rightbar {
    display: none;
    position: fixed;
    bottom: 16%;
    right: 0;
    z-index: 1001;
    .a-box {
        display: block;
        width: 52px;
        height: 52px;
        line-height: 52px;
        text-align: center;
        font-size: 32px;
        color: #fff;
        opacity: .7;
        background-color: #c9caca;
        cursor: pointer;
        transition: all .3s;
        &:hover {
            background-color: $baseColor;
        }
    }
    li {
        &:nth-child(2) .a-box {
            background-color: rgba($baseColor, .8);
            &:hover {
                background-color: $baseColor;
            }
        }
    }
    @media screen and (max-width: 750px) {
        right: px2rem(20px);
        .a-box {
            width: 32px;
            height: 32px;
            line-height: 32px;
            font-size: 20px;
            border-radius: 50%;
        }
        li {
            & + li {
                margin-top: 8px;
            }
        }
    }
}

.loding-page {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: 140px;
    background-color: #000;
    box-sizing: border-box;
    z-index: 9999;
    .en {
        position: relative;
        height: 144px;
        line-height: 144px;
        font-family: 'Gotham-Medium';
        font-size: 0;
        span {
            display: inline-block;
            vertical-align: top;
            font-size: 130px;
        }
        .word-cover {
            position: absolute;
            top: 0;
            left: 0;
            color: $baseColor;
            span {
                opacity: 0;
                transform: rotateY(90deg);
            }
        }
        .word span {
            transform-origin: center center 25px;
        }
        .word-default {
            position: absolute;
            top: 0;
            left: 0;
            color: #dcdddd;
        }
    }
    .cn {
        position: relative;
        margin-top: 20px;
        line-height: 36px;
        letter-spacing: 20px;
        font-size: 20px;
        color: #b5b5b6;
        z-index: 9;
        img {
            display: inline-block;
            vertical-align: middle;
            margin-top: -2px;
        }
        .symbol0 {
            margin: 0 6px 0 2px;
            letter-spacing: 0;
        }
        .symbol1 {
            margin-left: -20px;
            font-style: normal;
        }
    }
    &.anim {
        .en {
            .word-cover span {
                animation-duration: .6s;
                animation-fill-mode: forwards;
                animation-name: wordAnim1;
                animation-timing-function: ease;
                &:first-child {
                    animation-delay: .45s;
                }
                &:nth-child(2) {
                    animation-delay: .5s;
                }
                &:nth-child(3) {
                    animation-delay: .55s;
                }
                &:nth-child(4) {
                    animation-delay: .6s;
                }
                &:nth-child(5) {
                    animation-delay: .65s;
                }
                &:nth-child(6) {
                    animation-delay: .7s;
                }
                &:nth-child(7) {
                    animation-delay: .75s;
                }
                &:nth-child(8) {
                    animation-delay: .8s;
                }
                &:nth-child(9) {
                    animation-delay: .85s;
                }
                &:nth-child(10) {
                    animation-delay: .9s;
                }
                &:nth-child(11) {
                    animation-delay: .95s;
                }
                &:nth-child(12) {
                    animation-delay: 1s;
                }
                &:nth-child(13) {
                    animation-delay: 1.05s;
                }
                &:nth-child(14) {
                    animation-delay: 1.1s;
                }
                &:nth-child(15) {
                    animation-delay: 1.15s;
                }
            }
            .word-default span {
                animation-duration: .3s;
                animation-fill-mode: forwards;
                animation-name: wordAnim2;
                animation-timing-function: cubic-bezier(.6, 0, .7, .2);
                &:first-child {
                    animation-delay: .4s;
                }
                &:nth-child(2) {
                    animation-delay: .45s;
                }
                &:nth-child(3) {
                    animation-delay: .5s;
                }
                &:nth-child(4) {
                    animation-delay: .55s;
                }
                &:nth-child(5) {
                    animation-delay: .6s;
                }
                &:nth-child(6) {
                    animation-delay: .65s;
                }
                &:nth-child(7) {
                    animation-delay: .7s;
                }
                &:nth-child(8) {
                    animation-delay: .75s;
                }
                &:nth-child(9) {
                    animation-delay: .8s;
                }
                &:nth-child(10) {
                    animation-delay: .85s;
                }
                &:nth-child(11) {
                    animation-delay: .9s;
                }
                &:nth-child(12) {
                    animation-delay: .95s;
                }
                &:nth-child(13) {
                    animation-delay: 1s;
                }
                &:nth-child(14) {
                    animation-delay: 1.05s;
                }
                &:nth-child(15) {
                    animation-delay: 1.1s;
                }
            }
        }

    }
    @media screen and (max-width: 1440px) {
        padding-top: 120px;
        .en {
            height: 124px;
            line-height: 124px;
            span {
                font-size: 110px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .en {
            height: 100px;
            line-height: 100px;
            span {
                font-size: 86px;
            }
        }
    }
    @media screen and (max-width: 750px) {
        background: #00b7ee url(../images/loading1.jpg) center no-repeat;
        background-size: contain ;
        .wp1340 {
            display: none;
        }
    }
}


@keyframes wordAnim1 {
    100% {
        opacity: 1;
        transform: rotateY(0);
    }
}

@keyframes wordAnim2 {
    100% {
        opacity: 0;
        //transform: rotateX(90deg);
    }
}