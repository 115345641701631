.news-list {
    display: flex;
    flex-wrap: wrap;
    margin: -25px -35px 70px;
    li {
        padding: 0 10px 30px;
        width: (100% / 3);
        box-sizing: border-box;
    }
    a {
        display: block;
        height: 100%;
        padding: 25px 25px 32px;
        box-sizing: border-box;
        transition: all .4s;
        &:hover {
            background-color: #fff;
            box-shadow: 0 5px 15px 4px rgba(0, 0, 0, .08);
            .time {
                color: $baseColor;
                &:after {
                    opacity: 1;
                    width: 100%;
                }
            }
            .title {
                color: $baseColor;
            }
        }
    }
    .img {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: (278 / 400) * 100%;
        overflow: hidden;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .time {
        position: relative;
        margin: 25px 0 18px;
        line-height: 54px;
        font-family: 'Gotham-Medium';
        font-size: 22px;
        color: #595757;
        border-bottom: 1px solid #cbcbcb;
        transition: all .4s;
        &:after {
            content: '';
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 0;
            height: 1px;
            opacity: 0;
            background-color: $baseColor;
            transition: width .5s;
            z-index: 2;
        }
    }
    .title {
        margin-bottom: 5px;
        max-height: 48px;
        line-height: 24px;
        font-size: 16px;
        color: #000;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-word;
        transition: all .4s;
    }
    .p {
        max-height: 28px * 3;
        line-height: 28px;
        font-size: 14px;
        color: #898989;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        word-break: break-word;
    }
    @media screen and (max-width: 1440px) {
        margin: -20px -25px 60px;
        a {
            padding: 20px 20px 26px;
        }
    }
    @media screen and (max-width: 1200px) {
        margin: -15px -20px 50px;
        li {
            padding: 0 px2rem(5px) 25px;
        }
        a {
            padding: 15px 15px 26px;
        }
        .time {
            margin: 16px 0;
            line-height: 50px;
            font-size: 20px;
        }
    }
    @media screen and (max-width: 1024px) {
        margin: px2rem(-10px) px2rem(-15px) 30px;
        a {
            padding: px2rem(10px) px2rem(10px) px2rem(20px);
        }
        .time {
            margin: 8px 0 12px;
            line-height: 42px;
            font-size: 18px;
        }
        .title {
            font-size: 15px;
        }
        .p {
            max-height: 24px * 3;
            line-height: 24px;
            font-size: 13px;
        }
    }
    @media screen and (max-width: 750px) {
        li {
            padding-bottom: 16px;
            width: 50%;
        }
        .time {
            margin: 5px 0 10px;
            line-height: 34px;
            font-size: 14px;
        }
        .title {
            max-height: 44px;
            line-height: 22px;
        }
    }
}

.news-title {
    margin-bottom: 45px;
    text-align: center;
    h2 {
        line-height: 1.4;
        font-size: 30px;
        color: #221815;
    }
    .time {
        margin-top: 12px;
        font-size: 15px;
        color: #646464;
    }
    @media screen and (max-width: 1440px) {
        h2 {
            font-size: 28px;
        }
    }
    @media screen and (max-width: 1200px) {
        margin-bottom: 40px;
        h2 {
            font-size: 26px;
        }
    }
    @media screen and (max-width: 1024px) {
        margin-bottom: 35px;
        h2 {
            font-size: 24px;
        }
        .time {
            font-size: 14px;
        }
    }
    @media screen and (max-width: 750px) {
        margin-bottom: 20px;
        h2 {
            font-size: 20px;
        }
        .time {
            margin-top: 8px;
            font-size: 13px;
        }
    }
}

.news-article {
    @include articleReset(14px, 28px);
    color: #7d7d7d;
    overflow: hidden;
    p {
        margin-bottom: 15px;
    }
    .img {
        padding: 10px 0 70px;
        text-align: center;
        &-title {
            margin-top: 10px;
        }
    }
    .p-center {
        text-align: center;
    }
    @media screen and (max-width: 1024px) {
        .img {
            padding-bottom: 50px;
        }
    }
    @media screen and (max-width: 750px) {
        font-size: 13px;
        p {
            margin-bottom: 10px;
        }
        .img {
            padding-bottom: 30px;
        }
        .p-center {
            text-align: left;
        }
    }
}

.back-list {
    margin-top: 60px;
    font-size: 0;
    &-center {
        text-align: center;
    }
    &-mobile {
        display: none;
    }
    a {
        display: inline-block;
        vertical-align: top;
        padding: 0 30px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        color: $baseColor;
        border: 1px solid $baseColor;
        border-radius: 32px;
        transition: all .3s;
        &:hover {
            color: #fff;
            background-color: $baseColor;
        }
    }
    .a-mobile {
        display: none;
    }
    @media screen and (max-width: 1024px) {
        margin-top: 45px;
        text-align: center;
    }
    @media screen and (max-width: 750px) {
        margin-top: 40px;
        &-mobile {
            display: block;
        }
        a {
            margin: 0 px2rem(20px);
            padding: 0 20px;
            height: 26px;
            line-height: 26px;
            font-size: 13px;
        }
        .a-mobile {
            display: inline-block;
            vertical-align: top;
        }
    }
}

.news-recommend {
    margin-top: 85px;
    padding-top: 24px;
    border-top: 1px solid #d2d2d2;
    &-title {
        margin-bottom: 18px;
        font-size: 24px;
        color: #3e3a39;
    }
    &-list {
        li {
            a {
                position: relative;
                display: flex;
                align-items: center;
                padding-left: 8px;
                &:hover {
                    .title {
                        color: $baseColor;
                        &:before {
                            background-color: $baseColor;
                            border-color: #b8e0f7;
                        }
                    }
                }
                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    margin-top: -1px;
                    width: 1px;
                    height: 2px;
                    background-color: #727171;
                    border-right: 1px solid #dfdfdf;
                    border-left: 1px solid #dfdfdf;
                    transition: all .3s;
                }
            }
            .title {
                flex: 1;
                margin-right: 30px;
                min-width: 0;
                line-height: 34px;
                font-size: 16px;
                color: #727171;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                transition: all .3s;

            }
            .time {
                font-size: 17px;
                color: #9fa0a0;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        margin-top: 75px;
        &-title {
            margin-bottom: 14px;
            font-size: 22px;
        }
        &-list {
            li {
                .title {
                    font-size: 15px;
                }
                .time {
                    font-size: 15px;
                }
            }
        }
    }
    @media screen and (max-width: 750px) {
        margin-top: px2rem(120px);
        padding-top: 18px;
        &-title {
            font-size: 20px;
        }
        &-list {
            li {
                & + li {
                    margin-top: 14px;
                }
                a {
                    display: block;
                    &:before {
                        top: 11px;
                    }
                }
                .title {
                    margin-right: 0;
                    line-height: 22px;
                    white-space: normal;
                }
                .time {
                    display: block;
                    margin-top: 6px;
                    font-size: 13px;
                }
            }
        }
    }
}


.video-recommend,
.video-list {
    display: flex;
    flex-wrap: wrap;
    li {
        box-sizing: border-box;
        .box {
            background-color: #fff;
            cursor: pointer;
            transition: all .3s;
            &:hover {
                transform: translateY(-4px);
                box-shadow: 5px 10px 20px rgba(0, 0, 0, .12);
                .title,
                .info:after {
                    color: $baseColor;
                }
                .info {
                    border-color: #fff;
                }
                .img {
                    &-default {
                        z-index: 0;
                        opacity: 0;
                    }
                    &-hover {
                        z-index: 6;
                        opacity: 1;
                    }
                }
            }
        }
        .img {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: (353 / 623) * 100%;
            overflow: hidden;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: opacity .3s;
            }
            &-default {
                z-index: 6;
            }
            &-hover {
                z-index: 0;
                opacity: 0;
            }
        }
        .info {
            position: relative;
            padding: 18px 70px 24px 20px;
            border-left: 1px solid #e6e6e6;
            border-right: 1px solid #e6e6e6;
            border-bottom: 1px solid #e6e6e6;
            transition: all .3s;
            &:after {
                content: '\e6d0';
                position: absolute;
                right: 24px;
                top: 50%;
                transform: translateY(-50%);
                font-family: 'iconfont';
                font-size: 36px;
                color: #b8b8b9;
                transition: all .3s;
            }
            &-tag {
                margin-bottom: 3px;
                line-height: 1.5;
                font-size: 15px;
                color: #898989;
            }
        }
        .title {
            line-height: 1.5;
            font-size: 18px;
            color: #595757;
            transition: all .3s;
        }
    }
    @media screen and (max-width: 1024px) {
        li {
            .info {
                padding: 15px 60px 20px 20px;
                &:after {
                    right: 18px;
                    font-size: 32px;
                }
                &-tag {
                    font-size: 14px;
                }
            }
            .title {
                font-size: 17px;
            }
        }
    }
    @media screen and (max-width: 750px) {
        display: block;
        li {
            .info {
                padding: 13px 50px 15px 15px;
                &:after {
                    right: 12px;
                    font-size: 28px;
                }
                &-tag {
                    font-size: 13px;
                }
            }
            .title {
                font-size: 16px;
            }
        }
    }
}

.video-recommend {
    margin: 0 -45px;
    li {
        padding: 0 45px 90px;
        width: 50%;
    }
    @media screen and (max-width: 1440px) {
        margin: 0 -35px;
        li {
            padding: 0 35px 70px;
        }
    }
    @media screen and (max-width: 1024px) {
        margin: 0 -15px;
        li {
            padding: 0 15px 30px;
        }
    }
    @media screen and (max-width: 750px) {
        margin: 0;
        li {
            padding: 0 0 px2rem(50px);
            width: 100%;
        }
    }
}

.video-list {
    margin: 0 -18px 50px;
    li {
        padding: 0 18px 76px;
        width: (100% / 3);
    }
    @media screen and (max-width: 1024px) {
        margin: 0 -15px 40px;
        li {
            padding: 0 15px 30px;
            width: 50%;
        }
    }
    @media screen and (max-width: 750px) {
        margin: 0 0 30px;
        li {
            padding: 0 0 px2rem(50px);
            width: 100%;
        }
    }
}