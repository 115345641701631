.form-title {
    margin-bottom: 55px;
    text-align: center;
    h2 {
        font-size: 30px;
        color: #000;
    }
    p {
        line-height: 1.8;
        font-size: 15px;
        color: $baseColor;
    }
    @media screen and (max-width: 1200px) {
        margin-bottom: 45px;
        h2 {
            font-size: 26px;
        }
        p {
            font-size: 14px;
        }
    }
    @media screen and (max-width: 1024px) {
        margin-bottom: 40px;
    }
    @media screen and (max-width: 750px) {
        margin-bottom: 25px;
        h2 {
            font-size: 22px;
        }
        p {
            font-size: 12px;
        }
    }
}

.select2-results__option--group {
    .select2-results__options {
        display: none;
    }
}

.select2-container {
    &--default {
        .select2-results__option--disabled {
            display: none;
        }
        .select2-search--dropdown {
            .select2-search__field {
                padding: 0 20px;
                height: 45px;
                line-height: 45px;
                font-size: 14px;
                color: #464646;
                border: none;
                border-top: 1px solid #f6f6f6;
            }
        }
        .select2-results__option .select2-results__option {
            padding-left: 20px;
            font-size: 15px;
            color: #898989;
        }
        .select2-results__option--highlighted.select2-results__option--selectable,
        .select2-results__option--selected {
            color: #464646;
            background-color: #d3edfb;
        }
        .select2-results > .select2-results__options {
            max-height: 60vh;
            &::-webkit-scrollbar {
                width: 5px;
            }

            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
                background-color: #f6f6f6;
                border-radius: 2px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 3px;
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
                background-color: #666;
            }

            &::-webkit-scrollbar-button {
                background-color: #f6f6f6;
            }
        }
        .select2-results__group {
            position: relative;
            padding: 12px 20px;
            font-weight: normal;
            cursor: pointer;
            &:after {
                content: '\e773';
                position: absolute;
                right: 16px;
                font-family: 'iconfont';
                transform: rotate(90deg);
                font-size: 11px;
                color: #d2d2d2;
                transition: all .3s;
            }
            &.active {
                &:after {
                    transform: rotate(-90deg);
                }
            }
        }
    }
    @media screen and (max-width: 1200px) {
        &--default {
            .select2-search--dropdown {
                .select2-search__field {
                    padding: 0 15px;
                    height: 40px;
                    line-height: 40px;
                    font-size: 13px;
                }
            }
            .select2-results__option .select2-results__option {
                padding-left: 15px;
                font-size: 14px;
            }
            .select2-results__group {
                padding: 10px 15px;
                &:after {
                    right: 12px;
                }
            }
        }
    }
    @media screen and (max-width: 750px) {
        &--default {
            .select2-search--dropdown {
                .select2-search__field {
                    height: 30px;
                    line-height: 30px;
                    font-size: 13px;
                }
            }
            .select2-results__option .select2-results__option {
                padding-left: 15px;
                font-size: 13px;
            }
            .select2-results__group {
                padding: 8px 15px;
                &:after {
                    right: 8px;
                    font-size: 8px;
                }
            }
        }
    }
}

.select2-results__option {
    padding: 12px 20px;
    line-height: 22px;
    font-size: 16px;
    color: #464646;
    overflow: hidden;
    border-top: 1px solid #fff;
    word-break: break-word;
    @media screen and (max-width: 1440px) {
        font-size: 15px;
        line-height: 20px;
    }
    @media screen and (max-width: 1200px) {
        padding: 10px 15px;
        font-size: 14px;
    }
    @media screen and (max-width: 750px) {
        padding: 8px 15px;
        line-height: 18px;
        font-size: 13px;
    }
}

.select2-dropdown {
    border-color: #eee;
    background-color: #f6f6f6;
    border-radius: px2rem(8px);
    overflow: hidden;
    .select2-search {
        padding: 0;
        border: none;
    }
}


.normal-form {
    .li-pad20 {
        padding-left: 20px;
    }
    &-interval40 {
        .group-average,
        .group-custom {
            margin: 0 -20px;
        }
        li {
            padding: 0 20px;
            box-sizing: border-box;
        }
        .submit-btn {
            padding-top: 45px;
        }
    }
    &-interval18 {
        .group-average,
        .group-custom {
            margin: 0 -9px;
        }
        li {
            padding: 0 9px;
            box-sizing: border-box;
        }
        .submit-btn {
            padding-top: 80px;
        }
    }
    .group-average {
        display: flex;
        li {
            flex: 1;
        }
    }
    .group-custom {
        display: flex;
        flex-wrap: wrap;
        &-li31 {
            width: (100% / 3);
        }
        &-li32 {
            width: (100% / 3) * 2;
        }
        &-li51 {
            width: 20%;
        }
        &-li21 {
            width: 50%;
        }
        &-li41 {
            width: 25%;
        }
        &-li11 {
            width: 100%;
        }
    }
    .input-box,
    .select-box,
    .laydate-box,
    .textarea-box,
    .code-box {
        margin-bottom: 25px;
    }
    .input-box {
        display: flex;
        align-items: baseline;
        .select-box {
            flex: 1;
            min-width: 0;
            margin-bottom: 0;
        }
    }
    .input-text {
        margin-right: 15px;
        max-width: 80px;
        line-height: 1.4;
        font-size: 18px;
        color: #3c3c3c;
        em {
            margin-right: 4px;
            color: #f00;
        }
    }
    .textarea-box {
        display: flex;
        .input-text {
            padding-top: 13px;
        }
    }
    ::-webkit-input-placeholder {
        color: #aaa;
    }
    ::-moz-placeholder {
        color: #aaa;
    }
    :-ms-input-placeholder {
        color: #aaa;
    }
    .input,
    .input-unit {
        flex: 1;
        min-width: 0;
        line-height: 52px;
        border: 1px solid #c9c9c9;
        border-radius: px2rem(8px);
        overflow: hidden;
        input {
            display: block;
            padding: 0 20px;
            height: 52px;
            line-height: 52px;
            font-size: 15px;
            color: #464646;
            outline: none;
            background: none;
            border: none;
            box-sizing: border-box;
            -webkit-tap-highlight-color: transparent;
        }
    }
    .textarea {
        flex: 1;
        min-width: 0;
        border: 1px solid #c9c9c9;
        border-radius: px2rem(8px);
        overflow: hidden;
        textarea {
            display: block;
            width: 100%;
            padding: 13px 20px;
            line-height: 26px;
            font-size: 15px;
            color: #464646;
            outline: none;
            background: none;
            border: none;
            box-sizing: border-box;
            resize: none;
            overflow: auto;
        }
    }
    .input {
        input {
            width: 100%;
        }
    }
    .input-unit {
        display: flex;
        .unit {
            min-width: 60px;
            font-size: 16px;
            color: #464646;
        }
        input {
            flex: 1;
            min-width: 0;
            padding-right: 10px;
        }
    }
    .select-list {
        flex: 1;
        min-width: 0;
        display: flex;
        padding: 0 10px;
        line-height: 52px;
        border: 1px solid #c9c9c9;
        border-radius: px2rem(8px);
        overflow: hidden;
        .select {
            flex: 1;
            min-width: 0;
        }
        select {
            opacity: 0;
            font-size: 15px;
            color: #999;
        }
    }
    .select2-container {
        display: block;
        width: 100% !important;
        .select2-selection--single {
            height: 52px;
            .select2-selection__arrow {
                top: 0;
                right: 18px;
                height: 52px;
                b {
                    margin-left: -8px;
                    margin-top: -4px;
                    border-top-color: #a1a1a1;
                    border-width: 8px 8px 0;
                }
            }
            &:focus {
                outline: none;
            }
        }
        .select2-selection__rendered {
            padding-left: 20px;
            padding-right: 40px;
            &:focus {
                outline: none;
            }
        }
        &--default {
            .select2-selection--single {
                border: none;
                border-radius: none;
            }
            .select2-selection__rendered {
                line-height: 52px;
                color: #464646;
                font-size: 16px;
            }
        }
    }
    .submit-btn {
        text-align: center;
        button {
            display: inline-block;
            vertical-align: top;
            padding: 0 30px;
            height: 40px;
            line-height: 38px;
            color: $baseColor;
            font-size: 15px;
            border: 1px solid $baseColor;
            border-radius: 40px;
            background: none;
            outline: none;
            cursor: pointer;
            transition: all .3s;
            &:hover {
                color: #fff;
                background-color: $baseColor;
            }
        }
    }
    .select-box {
        line-height: 52px;
        border: 1px solid #c9c9c9;
        border-radius: px2rem(8px);
        overflow: hidden;
        select {
            opacity: 0;
        }
    }
    .laydate-box {
        position: relative;
        display: flex;
        align-items: center;
        padding-left: 20px;
        line-height: 52px;
        border: 1px solid #c9c9c9;
        border-radius: px2rem(8px);
        overflow: hidden;
        .input-text {
            margin-right: 6px;
            font-size: 16px;
        }
        .input {
            position: relative;
            border: none;
            input {
                position: relative;
                padding: 0 40px 0 0;
                z-index: 4;
            }
            &:after {
                content: '';
                position: absolute;
                right: 20px;
                top: 50%;
                margin-left: -8px;
                margin-top: -4px;
                border-style: solid;
                border-color: #a1a1a1 transparent transparent;
                border-width: 8px 8px 0;
                z-index: 0;
            }
        }
    }
    .code-box,
    .input-custom {
        display: flex;
        line-height: 52px;
        border: 1px solid #c9c9c9;
        border-radius: px2rem(8px);
        overflow: hidden;
        .input {
            border: none;
        }
    }
    .code-btn {
        padding: 0 10px;
        min-width: 105px;
        text-align: center;
        font-size: 15px;
        color: #787878;
        background-color: #f2f2f3;
        transition: all .3s;
        &.active {
            cursor: pointer;
            color: #fff;
            background-color: $baseColor;
        }
    }
    .input-custom {
        flex: 1;
        min-width: 0;
        .select-list {
            flex: 0 0 40%;
            border: none;
        }
        &-input {
            display: flex;
            align-items: center;
            flex: 1;
            min-width: 0;
            &-text {
                font-size: 15px;
                color: #aaa;
            }
            .input input {
                padding-left: 0;
            }
        }
    }
    &-interval30 {
        .group-average,
        .group-custom {
            margin: 0 -15px;
        }
        li {
            padding: 0 15px;
            box-sizing: border-box;
        }
        .input-box,
        .select-box,
        .laydate-box,
        .textarea-box,
        .code-box {
            margin-bottom: 40px;
        }
        .submit-btn {
            padding-top: 30px;
        }
    }
    @media screen and (max-width: 1440px) {
        .input-text {
            font-size: 17px;
        }
        .laydate-box {
            .input-text {
                font-size: 15px;
            }
            .input {
                input {
                    font-size: 14px;
                }
            }
        }
        .select2-container {
            &--default {
                .select2-selection__rendered {
                    font-size: 15px;
                }
            }
        }
    }
    @media screen and (max-width: 1200px) {
        .li-pad20 {
            padding-left: 15px;
        }
        &-interval40 {
            .group-average,
            .group-custom {
                margin: 0 -15px;
            }
            li {
                padding: 0 15px;
            }
            .submit-btn {
                padding-top: 40px;
            }
        }
        &-interval18 {
            .submit-btn {
                padding-top: 70px;
            }
        }
        .input-box,
        .select-box,
        .laydate-box,
        .textarea-box,
        .code-box {
            margin-bottom: px2rem(20px);
        }
        &-interval30 {
            .input-box,
            .select-box,
            .laydate-box,
            .textarea-box,
            .code-box {
                margin-bottom: 30px;
            }
        }
        .input-text {
            font-size: 16px;
        }
        .input,
        .input-unit {
            input {
                padding: 0 15px;
                font-size: 14px;
            }
        }
        .input-unit {
            .unit {
                min-width: 45px;
                font-size: 15px;
            }
        }
        .textarea {
            textarea {
                padding: 13px 15px;
                font-size: 14px;
            }
        }
        .select-list {
            padding: 0;
        }
        .select2-container {
            .select2-selection--single {
                .select2-selection__arrow {
                    right: 12px;
                }
            }
            .select2-selection__rendered {
                padding-left: 15px;
                padding-right: 32px;
                font-size: 14px;
            }
        }
        .laydate-box {
            padding-left: 15px;
            .input-text {
                font-size: 14px;
            }
            .input {
                input {
                    padding-right: 32px;
                    font-size: 13px;
                }
                &:after {
                    right: 15px;
                }
            }
        }
        .input-custom {
            .select-list {
                flex: 0 0 50%;
            }
            &-input {
                &-text {
                    font-size: 14px;
                }
            }
        }
        .code-btn {
            min-width: 90px;
            font-size: 14px;
        }
    }
    @media screen and (max-width: 1024px) {
        .li-pad20 {
            padding-left: 9px;
        }
        &-interval40 {
            .submit-btn {
                padding-top: 30px;
            }
        }
        &-interval18 {
            .submit-btn {
                padding-top: 60px;
            }
        }
        &-interval30 {
            .input-box,
            .select-box,
            .laydate-box,
            .textarea-box,
            .code-box {
                margin-bottom: px2rem(20px);
            }
        }
        .group-custom {
            &-li31-m {
                width: (100% / 3);
            }
            &-li32-m {
                width: (100% / 3) * 2;
            }
            &-li21-m {
                width: 50%;
            }
        }
        .input-text {
            font-size: 15px;
        }
        .input,
        .input-unit {
            line-height: 40px;
            input {
                height: 40px;
                line-height: 40px;
            }
        }
        .input-unit {
            .unit {
                min-width: 30px;
                font-size: 14px;
            }
        }
        .textarea {
            textarea {
                padding: 12px 15px;
                line-height: 24px;
            }
        }
        .select-list {
            line-height: 40px;
        }
        .select2-container {
            .select2-selection--single {
                height: 40px;
                .select2-selection__arrow {
                    height: 40px;
                    b {
                        margin-left: -6px;
                        margin-top: -3px;
                        border-width: 6px 6px 0;
                    }
                }
            }
            .select2-selection__rendered {
                padding-right: 26px;
            }
            &--default {
                .select2-selection__rendered {
                    line-height: 40px;
                    font-size: 14px;
                }
            }
        }
        .laydate-box {
            line-height: 40px;
            .input {
                input {
                    padding-right: 26px;
                }
                &:after {
                    margin-left: -6px;
                    margin-top: -3px;
                    border-width: 6px 6px 0;
                }
            }
        }
        .select-box {
            line-height: 40px;
        }
        .code-box,
        .input-custom {
            line-height: 40px;
        }
        .code-btn {
            min-width: 80px;
            font-size: 13px;
        }
        .submit-btn {
            button {
                height: 36px;
                line-height: 34px;
                font-size: 14px;
            }
        }
    }
    @media screen and (max-width: 750px) {
        .li-pad20 {
            padding-left: 0;
        }
        &-interval40 {
            .group-average,
            .group-custom {
                margin: 0;
            }
            li {
                padding: 0;
            }
            .submit-btn {
                padding-top: 20px;
            }
        }
        &-interval18 {
            .group-average,
            .group-custom {
                margin: 0;
            }
            li {
                padding: 0;
            }
            .submit-btn {
                padding-top: 30px;
            }
        }
        .group-average {
            display: block;
        }
        .group-custom {
            display: block;
            &-li51,
            &-li21,
            &-li41,
            &-li31,
            &-li32,
            &-li31-m,
            &-li32-m,
            &-li21-m {
                width: 100%;
            }
        }
        .input-text {
            margin-right: 10px;
            font-size: 14px;
            &-justify {
                text-align: justify;
                text-align-last: justify;
            }
        }
        .code-box {
            margin-left: 66px;
        }
        .input,
        .input-unit {
            input {
                font-size: 13px;
            }
        }
        .input-unit {
            .unit {
                font-size: 13px;
            }
        }
        .textarea {
            textarea {
                padding: 10px 15px;
                font-size: 13px;
            }
        }
        .select2-container {
            &--default {
                .select2-selection__rendered {
                    font-size: 13px;
                }
            }
        }
        .select-list {
            .select {
                &-21-sm {
                    flex: 0 0 50%;
                }
            }
        }
        .input-custom {
            display: block;
            .select-list {
                flex-wrap: wrap;
            }
            &-input {
                padding: 0 15px;
                &-text {
                    font-size: 13px;
                }
            }
        }
    }
}

.layui-laydate-header {
    padding-bottom: 10px !important;
    i {
        top: 50% !important;
        font-size: 16px !important;
        transform: translateY(-50%);
    }
}
.layui-laydate-footer span:hover {
    color: $baseColor !important;
}