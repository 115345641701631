.subbrand-title {
    margin-bottom: 20px;
    @media screen and (max-width: 1024px) {
        margin-bottom: 15px;
    }
    @media screen and (max-width: 750px) {
        margin-bottom: 12px;
    }
}

.subbrand-list {
    display: flex;
    flex-wrap: wrap;
    margin: 60px px2rem(-15px) 20px;
    li {
        padding: 0 px2rem(15px) 32px;
        width: (100% / 3);
        box-sizing: border-box;
    }
    a {
        position: relative;
        display: block;
        text-align: center;
        border-radius: 8px;
        overflow: hidden;
        &:hover {
            .title {
                transform: translateY(100%);
                opacity: 0;
            }
            .mask-box {
                transform: translateY(0);
                opacity: 1;
            }
        }
    }
    .img {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        & > img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .title {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 20px px2rem(20px);
        background-color: rgba(67, 67, 67, .6);
        box-sizing: border-box;
        z-index: 2;
        transition: all .15s .25s;
        img {
            height: 20px;
            max-width: 100%;
        }
    }
    .mask-box {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding: 20px;
        width: 100%;
        height: 100%;
        background-color: rgba(16, 25, 39, .7);
        box-sizing: border-box;
        opacity: 0;
        transform: translateY(-100%);
        transition: all .4s;
        z-index: 3;
        &-title {
            img {
                max-width: 100%;
                height: 24px;
            }
        }
        &-p {
            margin-top: 20px;
            line-height: 22px;
            font-size: 15px;
            color: #fff;
        }
    }
    .more {
        margin-top: 54px;
        display: inline-block;
        padding: 0 20px;
        height: 28px;
        line-height: 28px;
        font-size: 15px;
        color: #fff;
        border: 1px solid $baseColor;
        background-color: $baseColor;
        border-radius: 8px;
    }
    @media screen and (max-width: 1440px) {
        .title {
            img {
                height: 18px;
            }
        }
        .mask-box {
            &-title {
                img {
                    height: 22px;
                }
            }
            &-p {
                font-size: 14px;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        margin-top: 50px;
        margin-bottom: 0;
        .title {
            padding-top: 18px;
            padding-bottom: 18px;
            img {
                height: 16px;
            }
        }
        .mask-box {
            &-title {
                img {
                    height: 20px;
                }
            }
        }
        .more {
            margin-top: 45px;
        }
    }
    @media screen and (max-width: 1024px) {
        li {
            padding-bottom: 40px;
        }
        a {
            &:hover {
                .title {
                    transform: translateY(0);
                    opacity: 1;
                }
                .more {
                    color: #fff;
                    background-color: $baseColor;
                }
            }
        }
        .img {
            border-radius: 8px;
            overflow: hidden;
        }
        .title {
            padding-top: 15px;
            padding-bottom: 15px;
            img {
                height: 14px;
            }
        }
        .mask-box {
            position: relative;
            height: auto;
            background: none;
            padding: 0;
            transform: translateY(0);
            opacity: 1;
            &-title {
                display: none;
            }
            &-p {
                display: none;
            }
        }
        .more {
            margin-top: 20px;
            color: $baseColor;
            font-size: 13px;
            border-radius: 30px;
            background-color: #fff;
            transition: all .3s;
        }
    }
    @media screen and (max-width: 750px) {
        margin-top: px2rem(30px);
        li {
            padding-top: px2rem(30px);
            padding-bottom: px2rem(30px);
            width: 50%;
        }
        .title {
            padding-top: 11px;
            padding-bottom: 10px;
            img {
                height: 12px;
            }
        }
        .mask-box {
            position: relative;
            height: auto;
            background: none;
            padding: 0;
            transform: translateY(0);
            opacity: 1;
            &-title {
                display: none;
            }
            &-p {
                display: none;
            }
        }
        .more {
            margin-top: 10px;
            padding: 0 15px;
            height: 26px;
            line-height: 26px;
            font-size: 12px;
        }
    }
}


.subbrand-page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    @media screen and (max-width: 1024px) {
        position: static;
        height: auto;
    }
}

.subbrand-swiper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 3;
    & > .swiper-wrapper > .swiper-slide {
        overflow: hidden;
    }
    @media screen and (max-width: 1024px) {
        position: static;
        height: auto;
        & > .swiper-wrapper {
            display: block;
        }
    }
}

.subbrand-footer {
    position: relative;
    visibility: visible;
    height: auto;
    .footer {
        padding-top: 70px;
    }
    @media screen and (max-width: 1024px) {
        position: static;
        .footer {
            padding-top: 0;
        }
    }
}

.subbrand-banner {
    position: relative;
    padding-top: 80px;
    box-sizing: border-box;
    &-box {
        width: 100%;
        height: 100%;
        overflow: hidden;
        .img-pc,
        .img-mobile {
            width: 100%;
            height: 100%;
            background: #fff none no-repeat center center;
            background-size: cover;
        }
        .img-mobile {
            display: none;
        }
        .swiper-slide {
            display: block;
        }
    }
    .subbrand-banner-pagination {
        position: absolute;
        left: 0;
        bottom: 3.5vh;
        width: 100%;
        text-align: center;
        z-index: 9;
        .swiper-pagination-bullet {
            margin: 0 5px;
            width: 11px;
            height: 11px;
            border: 1px solid $baseColor;
            outline: none;
            background: none;
            opacity: 1;
            &-active {
                background-color: $baseColor;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        padding-top: 0;
        height: calc(100vh - .5rem);
        .subbrand-banner-pagination {
            .swiper-pagination-bullet {
                width: 9px;
                height: 9px;
            }
        }
    }
    @media screen and (max-width: 750px) {
        &-box {
            .img-mobile {
                display: block;
            }
            .img-pc {
                display: none;
            }
        }
        .subbrand-banner-pagination {
            .swiper-pagination-bullet {
                margin: 0 4px;
                width: 6px;
                height: 6px;
            }
        }
    }
}


.subbrand-detail {
    display: flex;
    align-items: center;
    padding-top: 55px;
    box-sizing: border-box;
    .wd1340 {
        display: flex;
    }
    &-article {
        position: relative;
        width: 50%;
        margin-right: 74px;
        @include articleReset(15px, 30px);
        color: #727171;
        .article-scroller {
            position: absolute;
            top: 0;
            left: 0;
            right: -25px;
            height: 100%;
            overflow: hidden;
            &-wp {
                padding-right: 25px;
            }
        }
        p {
            margin-bottom: 30px;
        }
        .bscroll-vertical-scrollbar {
            width: 5px !important;
            border-radius: 5px;
            background-color: #eee;
            z-index: 887 !important;
        }
        .bscroll-indicator {
            background-color: $baseColor !important;
            border-color: $baseColor !important;
        }
        .subbrand-article-title {
            display: none;
            margin-bottom: 25px;
            text-align: center;
            color: $baseColor;
            .title {
                height: 36px;
                img {
                    max-height: 100%;
                    max-width: 100%;
                }
            }
            p {
                margin-top: 6px;
                font-family: 'Gotham-book';
                font-size: 18px;
            }
            &-sub {
                margin-bottom: 36px;
                line-height: 42px;
                font-size: 25px;
                font-weight: bold;
                &:after {
                    content: '';
                    display: block;
                    margin-top: 32px;
                    width: 36px;
                    height: 6px;
                    background-color: #727171;
                }
            }
        }
    }
    &-img {
        flex: 1;
        min-width: 0;
        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }
    @media screen and (max-width: 1440px) {
        &-article {
            font-size: 14px;
            .subbrand-article-title {
                &-sub {
                    margin-bottom: 26px;
                    line-height: 34px;
                    font-size: 22px;
                    &:after {
                        margin-top: 25px;
                        height: 5px;
                    }
                }
            }
            p {
                margin-bottom: 20px;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        &-article {
            margin-right: 50px;
            .article-scroller {
                right: -15px;
                &-wp {
                    padding-right: 15px;
                }
            }
            .subbrand-article-title {
                &-sub {
                    margin-bottom: 20px;
                    line-height: 32px;
                    font-size: 20px;
                    &:after {
                        margin-top: 20px;
                        height: 4px;
                    }
                }
            }
            p {
                margin-bottom: 15px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        display: block;
        padding: 80px 0;
        .wd1340 {
            display: block;
        }
        &-article {
            width: 100%;
            margin-right: 0;
            .article-scroller {
                position: relative;
                right: 0;
                height: auto;
                touch-action: auto !important;
                &-wp {
                    padding-right: 0;
                }
            }
            .subbrand-article-title {
                display: block;
                &-sub {
                    margin-bottom: 15px;
                    font-size: 18px;
                    &:after {
                        display: none;
                    }
                }
            }
        }
        &-img {
            display: none
        }
    }
    @media screen and (max-width: 750px) {
        padding: px2rem(110px) 0;
        &-article {
            line-height: 28px;
            font-size: 13px;
            .subbrand-article-title {
                margin-bottom: 15px;
                .title {
                    height: 20px;
                }
                p {
                    margin-top: 0;
                    font-size: 12px;
                }
                &-sub {
                    margin-bottom: 10px;
                    line-height: 26px;
                    font-size: 15px;
                    &:after {
                        display: none;
                    }
                }
            }
            p {
                margin-bottom: 10px;
            }
        }
    }
}

.subbrand-detail2 {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    &-article {
        display: none;
        @include articleReset(14px, 30px);
        color: #727171;
        p {
            margin-bottom: 15px;
        }
        .title {
            margin-bottom: px2rem(35px);
            text-align: center;
            img {
                max-height: 50px;
                max-width: 100%;
            }
        }
        .p {
            font-size: 16px;
            color: #2c2c2c;
        }
        .img {
            padding: px2rem(20px) 0 px2rem(10px);
            img {
                width: 100%;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        padding: 90px px2rem(30px);
        background: none;
        box-sizing: border-box;
        &-article {
            display: block;
        }
    }
    @media screen and (max-width: 750px) {
        padding-top: px2rem(110px);
        padding-bottom: px2rem(110px);
        &-article {
            font-size: 13px;
            line-height: 28px;
            .title {
                img {
                    max-height: 38px;
                }
            }
            .p {
                font-size: 15px;
            }
            p {
                margin-bottom: 10px;
            }
        }
    }
}

.subbrand-video {
    &-box {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        cursor: pointer;
        &:hover {
            .video-link-icon {
                border-color: $baseColor;
                background-color: rgba(0, 106, 143, .4);
            }
        }
    }
    @media screen and (max-width: 1024px) {
        &-box {
            height: 0;
            padding-bottom: (970 / 1920) * 100%;
        }
    }
    @media screen and (max-width: 750px) {
        &-box {
            padding-bottom: (420 / 750) * 100%;
        }
    }
}

.subbrand-pro {
    display: flex;
    align-items: center;
    padding-top: 55px;
    box-sizing: border-box;
    &-title {
        margin-bottom: 6vh;
        text-align: center;
        .title {
            font-size: 30px;
            color: #000;
        }
        p {
            margin-top: 2px;
            line-height: 26px;
            font-size: 15px;
            color: #727171;
        }
    }
    &-swiper {
        .swiper-slide {
            a {
                display: block;
                border-bottom: 1px solid #e5e5e5;
                &:hover {
                    .img img {
                        transform: scale(1.1);
                    }
                    .info-more {
                        color: $baseColor;
                    }
                }
            }
            .img {
                position: relative;
                width: 100%;
                height: 0;
                padding-bottom: (433 / 647) * 100%;
                overflow: hidden;
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    transition: all .5s;
                }
            }
            .info {
                padding: 32px 0 18px;
                &-title {
                    font-weight: bold;
                    font-size: 20px;
                    color: #666464;
                }
                &-bottom {
                    display: flex;
                    margin-top: 5px;
                    line-height: 20px;
                    font-size: 15px;
                    color: #898989;
                }
                &-p {
                    flex: 1;
                    min-width: 0;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                &-more {
                    margin-left: 20px;
                    transition: all .3s;
                }
            }
        }
    }
    &-pagination {
        margin-top: 6vh;
        text-align: center;
        .swiper-pagination-bullet {
            margin: 0 5px;
            width: 11px;
            height: 11px;
            border: 1px solid $baseColor;
            outline: none;
            background: none;
            opacity: 1;
            &-active {
                background-color: $baseColor;
            }
        }
    }
    @media screen and (max-width: 1440px) {
        &-swiper {
            .swiper-slide {
                .info {
                    padding: 24px 0 12px;
                    &-title {
                        font-size: 18px;
                    }
                    &-bottom {
                        font-size: 14px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1200px) {
        &-title {
            .title {
                font-size: 26px;
            }
            p {
                line-height: 24px;
                font-size: 14px;
            }
        }
        &-swiper {
            .swiper-slide {
                .info {
                    padding: 18px 0 10px;
                    &-title {
                        font-size: 16px;
                    }
                    &-bottom {
                        font-size: 13px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        display: block;
        padding: 80px 0;
        &-title {
            margin-bottom: px2rem(45px);
            .title {
                font-size: 24px;
            }
        }
        &-pagination {
            margin-top: px2rem(45px);
            .swiper-pagination-bullet {
                width: 9px;
                height: 9px;
            }
        }
    }
    @media screen and (max-width: 750px) {
        padding: px2rem(100px) 0 px2rem(110px);
        &-title {
            .title {
                font-size: 20px;
            }
            p {
                margin-top: 2px;
                line-height: 22px;
                font-size: 13px;
            }
        }
        &-swiper {
            .swiper-slide {
                a {
                    border-bottom: none;
                }
                .info {
                    padding: 10px 0 0;
                    &-bottom {
                        display: block;
                    }
                    &-more {
                        display: none;
                    }
                    &-p {
                        white-space: normal;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        word-break: break-word;
                    }
                }
            }
        }
        &-pagination {
            .swiper-pagination-bullet {
                margin: 0 4px;
                width: 6px;
                height: 6px;
            }
        }
    }
}