.index-page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    @media screen and (max-width: 1024px) {
        position: static;
        height: auto;
    }
}
.index-dot {
    display: none;
    @media screen and (max-width: 1024px) {
        display: block;
        text-align: center;
        padding-top: px2rem(120px);
    }
    img {
        width: px2rem(29px);
    }
}

.index-swiper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 3;
    & > .swiper-wrapper > .swiper-slide {
        overflow: hidden;
    }
    @media screen and (max-width: 1024px) {
        position: static;
        height: auto;
        & > .swiper-wrapper {
            display: block;
        }
    }
}

.index-footer {
    position: relative;
    visibility: visible;
    height: auto;
    .footer {
        padding-top: 70px;
    }
    @media screen and (max-width: 1024px) {
        position: static;
        .footer {
            padding-top: 0;
        }
    }
}


.index-more {
    margin-top: 6vh;
    text-align: center;
    a {
        display: inline-block;
        padding: 0 22px;
        height: 30px;
        line-height: 30px;
        font-size: 15px;
        color: $baseColor;
        border: 1px solid $baseColor;
        border-radius: 15px;
        @media screen and (min-width: 1024px) {
            transition: all .3s;
        }
        &:hover {
            color: #fff;
            background-color: $baseColor;
        }
    }
    @media screen and (max-width: 1600px) {
        margin-top: 5vh;
    }
    @media screen and (max-width: 1024px) {
        margin-top: px2rem(100px);
    }
    @media screen and (max-width: 750px) {
        a {
            padding: 0 18px;
            height: 30px;
            line-height: 30px;
            font-size: 15px;
        }
    }
}

.index-banner {
    position: relative;
    padding-top: 80px;
    box-sizing: border-box;
    &-box {
        width: 100%;
        height: 100%;
        overflow: hidden;
        .img-pc,
        .img-mobile {
            width: 100%;
            height: 100%;
            background: #fff none no-repeat center center;
            background-size: cover;
        }
        .img-mobile {
            display: none;
        }
        .mejs__container,
        .mejs__poster,
        .mejs__overlay,
        video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100% !important;
            height: 100% !important;
            overflow: hidden;
        }
        video {
            object-fit: fill;
        }
        .video {
            position: relative;
            width: 100%;
            height: 100%;
            cursor: pointer;
            img {
                display: none;
            }
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 99;
            }
            &-link-icon {
                display: none;
                z-index: 100;
            }
            &:hover .video-link-icon {
                border-color: $baseColor;
                background-color: rgba(0, 106, 143, .4);
            }
        }
        .mejs__overlay-button,
        .mejs__overlay-loading,
        .mejs__overlay-error,
        .mejs__controls {
            visibility: hidden;
        }
        .mejs__poster,
        .video {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
        .index-more {
            position: absolute;
            bottom: 10vh;
            left: 50%;
            transform: translateX(-50%);
            z-index: 101;
        }
        .swiper-slide {
            display: block;
        }
    }
    .index-banner-pagination {
        position: absolute;
        left: 0;
        bottom: 3.5vh;
        width: 100%;
        text-align: center;
        z-index: 9;
        .swiper-pagination-bullet {
            margin: 0 5px;
            width: 11px;
            height: 11px;
            border: 1px solid $baseColor;
            outline: none;
            background: none;
            opacity: 1;
            &-active {
                background-color: $baseColor;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        padding-top: 0;
        // height: calc(100vh - .5rem);
        height: px2rem(1220px);

        .mejs__container,
        .mejs__poster,
        .mejs__overlay,
        video {
            display: none;
        }
        .video-link-icon {
            display: block;
        }
        .index-banner-pagination {
            .swiper-pagination-bullet {
                width: 9px;
                height: 9px;
            }
        }
    }
    @media screen and (max-width: 750px) {
        .img-mobile {
            display: block;
        }
        .img-pc {
            display: none;
        }
        .index-more {
            bottom: 9vh;
        }
        .index-banner-pagination {
            .swiper-pagination-bullet {
                margin: 0 4px;
                width: 6px;
                height: 6px;
            }
        }
    }
}

.index-title {
    text-align: center;
    h2 {
        margin-bottom: 6px;
        line-height: 1;
        font-size: 41px;
        color: #000;
        font-family: Gotham-Medium;
        letter-spacing: -2px;
    }
    h3 {
        font-size: 25px;
        color: #3e3a39;
    }
    @media screen and (max-width: 1440px) {
        h2 {
            font-size: 38px;
        }
        h3 {
            font-size: 24px;
        }
    }
    @media screen and (max-width: 1024px) {
        h2 {
            font-size: 32px;
        }
        h3 {
            font-size: 22px;
        }
    }
    @media screen and (max-width: 750px) {
        h2 {
            margin-bottom: 2px;
            font-size: 20px;
            letter-spacing: -1px;
        }
        h3 {
            font-size: 21px;
        }
    }
}

.index-s-title {
    text-align: center;
    margin-top: px2rem(40px);
    .cn {
        font-size: px2rem(28px);
        color: #2f2725;
    }
    .en {
        font-size: px2rem(20px);
        color: #3e3a39;
        margin-top: px2rem(8px);
    }
}

.logo-title {
    text-align: center;
    img {
        max-height: 100%;
        max-width: 100%;
    }
    &-img {
        height: 30px;
    }
    &-bottom {
        margin-top: 18px;
        height: 23px;
    }
    @media screen and (max-width: 1440px) {
        &-img {
            height: 28px;
        }
        &-bottom {
            margin-top: 16px;
            height: 20px;
        }
    }
    @media screen and (max-width: 1024px) {
        &-img {
            height: 24px;
        }
        &-bottom {
            height: 20px;
        }
    }
    @media screen and (max-width: 750px) {
        &-img {
            height: 18px;
        }
        &-bottom {
            margin-top: 10px;
            height: 20px;
        }
    }
}

.index-about {
    display: flex;
    align-items: center;
    padding-top: 55px;
    background-color: #fff;
    box-sizing: border-box;
    &-content,
    &-mb-content {
        margin-bottom: 0;
        text-align: center;
        padding-top: 75px;
        font-size: 15px;
        color: #898989;
        line-height: 25px;
        .logo-title {
            margin-bottom: 25px;
            @media screen and (max-width: 1024px) {
                margin-bottom: px2rem(40px);
                img {
                    width: px2rem(312px);
                }
            }
        }
        p {
            line-height: 25px;
            font-size: 14px;
            color: #898989;
        }
    }
    &-content {
        @media screen and (max-width: 1024px) {
            display: none;
        }
    }
    &-mb-content {
        display: none;
        @media screen and (max-width: 1024px) {
            display: block;
            padding-top: px2rem(50px);
            line-height: px2rem(42px);
        }
    }
    &-list {
        display: flex;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        li {
            width: (100% / 6);
            &:nth-child(even) {
                .box {
                    background-color: rgba(238, 238, 238, .15);
                }
            }
        }
        .box {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: (449 / 224) * 100%;
            &:hover {
                >img {
                    opacity: 1.2;
                    transform: scale(1.17);
                }
            }
            >img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                transform-origin: center center;
                box-shadow: 0 4px 12px rgba(0, 0, 0, .3);
                transition: all .5s;
                object-fit: cover;
            }
        }
        .data {
            position: absolute;
            top: 50%;
            left: 20px;
            right: 20px;
            transform: translateY(-50%);
            text-align: center;
            line-height: 1.5;
            color: #fff;
            font-size: 15px;
            z-index: 2;
            &-num {
                margin-bottom: 5px;
                span {
                    line-height: 1;
                    font-size: 48px;
                    font-family: 'Helvetica Condensed Bold';
                }
                sup {
                    display: inline-block;
                    vertical-align: top;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                }
            }
            .icon {
                height: 48px;
            }
        }
    }
    .video-link {
        display: none;
        margin: px2rem(40px) px2rem(-30px) 0;
    }
    @media screen and (max-width: 1600px) {
        &-content {
            margin-bottom: 5vh;
        }
    }
    @media screen and (max-width: 1440px) {
        &-content {
            .logo-title {
                margin-bottom: 18px;
            }
        }
        &-list {
            .data {
                font-size: 12px;
                &-num {
                    span {
                        font-size: 36px;
                    }
                    sup {
                        font-size: 13px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        display: block;
        padding: 80px 0;
        &-content {
            margin-bottom: 40px;
            padding-top: 0;
        }
        &-list {
            .data {
                font-size: 12px;
                &-num {
                    span {
                        font-size: 26px;
                    }
                    sup {
                        font-size: 12px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 750px) {
        position: relative;
        padding: px2rem(110px) 0 px2rem(125px);
        margin-bottom: 0;
        // &:after {
        //     content: '';
        //     position: absolute;
        //     bottom: 0;
        //     left: 0;
        //     width: 100%;
        //     height: 6px;
        //     background: url(../images/line1.png) center center no-repeat;
        //     background-size: contain;
        // }
        &-content {
            margin-bottom: px2rem(30px);
            .logo-title {
                margin-bottom: 15px;
            }
            .p {
                font-size: 13px;
            }
        }
        &-list {
            margin: 0 px2rem(-30px);
            flex-wrap: wrap;
            justify-content: center;
            background: none !important;
            li {
                position: relative;
                padding: px2rem(25px) px2rem(10px);
                width: (100% / 3);
                box-sizing: border-box;
                &:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    margin-top: -16px;
                    width: 1px;
                    height: 32px;
                    background-color: #b5b5b5;
                }
                &:nth-child(even) {
                    .box {
                        background: none;
                    }
                }
                &:nth-child(1) {
                    &:before {
                        display: none;
                    }
                }
                &:nth-child(4) {
                    &:before {
                        display: none;
                    }
                }
            }
            .box {
                height: auto;
                padding-bottom: 0;
                >img {
                    display: none;
                }
                .icon {
                    // height: auto;
                    height: 27px;
                    margin-bottom: 3px;
                    img {
                        width: px2rem(51px);
                    }
                }
            }
            .data {
                position: relative;
                top: 0;
                left: 0;
                right: auto;
                transform: translateY(0);
                color: #221815;
                font-size: 13px;
                &-num {
                    margin-bottom: 3px;
                    span {
                        font-size: 26px;
                    }
                    sup {
                        font-size: 12px;
                    }
                }
            }
        }
        .video-link {
            display: block;
        }
    }
}

.index-pro {
    position: relative;
    .index-title {
        display: none;
        margin-bottom: px2rem(30px);
        @media screen and (max-width: 1024px) {
            margin-bottom: px2rem(60px);
        }
    }
    .index-mb-pro {
        display: none;
        @media screen and (max-width: 1024px) {
            display: block;
            padding: 0 px2rem(35px);
            .img-box {
                display: block;
                img {
                    width: 100%;
                }
            }
        }
        .index-mb-category {
            position: relative;
            img {
                width: 100%;
            }
            .link-box {
                display: flex;
                flex-flow: wrap;
                li {
                    width: (100% / 3);
                    box-sizing: border-box;
                    margin-bottom: px2rem(12px);
                    &:nth-child(3n+1) {
                        padding-right: px2rem(8px);
                    }
                    &:nth-child(3n+2) {
                        padding: 0 px2rem(4px);
                    }
                    &:nth-child(3n+3) {
                        padding-left: px2rem(8px);
                    }
                }
                .img-box {
                    @include zoomImg(219px, 273px);
                    img {
                        border-radius: px2rem(10px);
                    }
                }
                a {
                    display: block;
                }
            }
        }
    }
    &-more {
        display: none;
        @media screen and (max-width: 1024px) {
            display: block;
            padding-top: px2rem(55px);
            text-align: center;
        }
        img {
            // width: px2rem(375px);
            width: 100%;
        }
    }
    &-swiper {
        @media screen and (max-width: 1024px) {
            display: none;
        }
        .img-box {
            display: block;
            height: 100vh;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    &-thumbs-swiper {
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: rgba(#000, .37);
        z-index: 10;
        width: 100%;
        box-sizing: border-box;
        padding: 0 80px;
        @media screen and (max-width: 1024px) {
            display: none;
        }
        .swiper-slide {
            text-align: center;
            padding: 35px 0;
        }
        .swiper-slide-thumb-active {
            .index-thums-item {
                background-color: rgba(#fff, .15);
                transform: scale(1.05);
            }
        }
    }
    .index-thums-item {
        text-align: center;
        cursor: pointer;
        padding: 9px 9px 14px;
        display: inline-block;
        transition: .3s;
        border-radius: 10px;
        &:hover {
            background-color: rgba(#fff, .15);
            transform: scale(1.05);
        }
        .img-box {
            position: relative;
            width: px2vw(110);
            height: px2vw(110);
            margin: 0 auto;
            transition: .3s;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 10px;
            }
        }
        .title-box {
            margin-top: 16px;
        }
    }
    &-prev,
    &-next {
        position: absolute;
        top: 50%;
        font-size: px2rem(28px);
        color: $baseColor;
        z-index: 99;
        outline: none;
        cursor: pointer;
        transition: all .3s;
        &.swiper-button-disabled {
            color: #9d9b9c;
        }
    }
    &-prev {
        left: px2rem(16px);
        transform: translateY(-50%) rotate(-180deg);
    }
    &-next {
        right: px2rem(16px);
        transform: translateY(-50%);
    }
    @media screen and (max-width: 1440px) {
        &-swiper {
            height: 190px;
            .swiper-slide {
                height: 95px;
                &:before {
                    height: 66px;
                }
                .icon {
                    width: 64px;
                    height: 40px;
                    line-height: 40px;
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .index-title {
            display: block;
        }
        &-bd {
            height: auto;
            a {
                height: 0;
                padding-bottom: (969 / 1920) * 100%;
            }
            .img-mobile,
            .img-pc {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
            }
        }
        &-swiper {
            position: relative;
            bottom: auto;
            padding: 0 px2rem(50px);
            height: 120px;
            background: none;
            &:before {
                left: px2rem(50px);
                right: px2rem(50px);
                background-color: #c9caca;
            }
            .swiper-slide {
                padding: 8px 24px;
                height: 60px;
                &:before {
                    height: 40px;
                    background-color: #c9caca;
                }
                .icon {
                    margin-right: 12px;
                    width: 56px;
                    height: 35px;
                    line-height: 35px;
                }
                .info {
                    flex: 1;
                    min-width: 0;
                    width: auto;
                    color: #3e3a39;
                }
                &.on {
                    background: none;
                    .icon-default {
                        display: none;
                        &-m {
                            display: inline-block;
                        }
                    }
                    .icon {
                        &-default-m,
                        &-hover {
                            display: none;
                        }
                        &-hover-m {
                            display: inline-block;
                        }
                    }
                    .info {
                        color: $baseColor;
                    }
                }
                h3 {
                    font-weight: normal;
                }
                p {
                    display: none;
                }
            }
        }
        &-prev,
        &-next {
            &.swiper-button-disabled {
                color: #c9caca;
            }
        }
    }
    @media screen and (max-width: 750px) {
        &-bd {
            a {
                padding-bottom: (650 / 750) * 100%;
            }
            .img-pc {
                display: none;
            }
            .img-mobile {
                display: block;
            }
        }
        &-swiper {
            height: 100px;
            .swiper-slide {
                padding: 4px px2rem(20px);
                height: 50px;
                &:before {
                    height: 34px;
                }
                .icon {
                    margin-right: 5px;
                    width: 38px;
                    height: 24px;
                    line-height: 24px;
                }
                h3 {
                    font-size: 13px;
                }
            }
        }
    }
}

.index-news {
    display: flex;
    align-items: center;
    padding-top: 55px;
    background-color: #fff;
    box-sizing: border-box;
    .index-title {
        margin-bottom: 6vh;
    }
    &-swiper {
        position: relative;
        .swiper-slide {
            a {
                display: flex;
                justify-content: space-between;
                background-color: #fff;
                &:hover {
                    .img img {
                        transform: scale(1.1);
                        transition: transform .5s, opacity 0s;
                    }
                    .title,
                    .more,
                    .more i {
                        color: $baseColor
                    }
                }
            }
            .info {
                width: 50%;
                padding: 40px 135px 0 0;
                box-sizing: border-box;
            }
            .img {
                order: 2;
                position: relative;
                width: 50%;
                height: 0;
                padding-bottom: (468 / 670) * 50%;
                overflow: hidden;
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 2;
                    object-fit: cover;
                    transition: transform .5s;
                }
            }
            .title {
                margin-bottom: 26px;
                max-height: 76px;
                line-height: 38px;
                font-size: 28px;
                color: #000;
                overflow: hidden;
                -o-text-overflow: ellipsis;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                word-break: break-word;
                transition: all .3s;
            }
            .time {
                margin-bottom: 8px;
                line-height: 1;
                font-size: 22px;
                color: #898989;
                font-family: GothamExLight;
            }
            .p {
                max-height: 28px * 6;
                font-size: 14px;
                line-height: 28px;
                color: #898989;
                overflow: hidden;
                -o-text-overflow: ellipsis;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 6;
                -webkit-box-orient: vertical;
                word-break: break-word;
            }
            .more {
                display: flex;
                align-items: center;
                margin-top: 25px;
                font-size: 14px;
                color: #3e3a39;
                transition: all .3s;
                i {
                    margin-left: 8px;
                    font-size: 40px;
                    color: #898989;
                    transition: all .3s;
                }
            }
        }
        .index-news-pagination {
            margin-top: 3vh;
            bottom: 0;
            width: 100%;
            text-align: center;
            z-index: 9;
            .swiper-pagination-bullet {
                margin: 0 5px;
                width: 11px;
                height: 11px;
                border: 1px solid $baseColor;
                outline: none;
                background: none;
                opacity: 1;
                &-active {
                    background-color: $baseColor;

                }
            }
        }
    }
    @media screen and (max-width: 1440px) {
        &-swiper {
            .swiper-slide {
                .info {
                    padding: 20px 60px 0 0;
                }
                .title {
                    max-height: 68px;
                    line-height: 34px;
                    font-size: 24px;
                }
                .p {
                    max-height: 28px * 3;
                    -webkit-line-clamp: 3;
                }
                .time {
                    font-size: 20px;
                }
                .more {
                    i {
                        font-size: 36px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        display: block;
        padding: 80px 0;
        .index-title {
            margin-bottom: px2rem(40px);
        }
        &-swiper {
            .swiper-slide {
                .info {
                    padding: 10px 40px 0 0;
                }
                .title {
                    margin-bottom: 18px;
                    max-height: 52px;
                    line-height: 26px;
                    font-size: 18px;
                }
                .p {
                    max-height: 26px * 3;
                    line-height: 26px;
                    font-size: 13px;
                }
                .time {
                    font-size: 16px;
                }
                .more {
                    margin-top: 15px;
                    i {
                        font-size: 30px;
                    }
                }
            }
            .index-news-pagination {
                margin-top: px2rem(55px);
                .swiper-pagination-bullet {
                    width: 9px;
                    height: 9px;
                }
            }
        }
        .index-more {
            margin-top: px2rem(60px);
        }
    }
    @media screen and (max-width: 750px) {
        padding: px2rem(110px) 0;
        &-swiper {
            .swiper-slide {
                a {
                    display: block;
                }
                .info {
                    width: 100%;
                    padding: 18px 0 0;
                }
                .img {
                    width: 100%;
                    padding-bottom: (468 / 670) * 100%;
                }
                .title {
                    margin-bottom: 14px;
                    max-height: 50px;
                    line-height: 25px;
                    font-size: 17px;
                }
                .time {
                    margin-bottom: 5px;
                    font-size: 16px;
                }
                .more {
                    display: none;
                }
            }
            .index-news-pagination {
                .swiper-pagination-bullet {
                    margin: 0 4px;
                    width: 6px;
                    height: 6px;
                }
            }
        }
    }
}

.index-recru {
    display: none;
    @media screen and (max-width: 1024px) {
        display: block;
        padding-bottom: px2rem(50px);
    }
    &-wrapper {
        padding: 0 px2rem(35px);
    }
    .recru-img {
        width: 100%;
        position: relative;
        margin-top: px2rem(50px);
        .img-box {
            img {
                width: 100%;
            }
        }
    }
    .link-box {
        height: 100%;
        width: 100%;
        bottom: 0;
        left: 0;
        position: absolute;
        display: flex;
        flex-flow: wrap;
        a {
            display: inline-block;
            width: 50%;
            height: 40%;
            &:nth-child(1) {
                width: 100%;
                height: 60%;
            }
        }
    }
}