.header {
    position: relative;
    width: 100%;
    height: 80px;
    background-color: $baseColor;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .08);
    z-index: 999;
    &-index {
        position: fixed;
        top: 0;
        left: 0;
        transition: transform .5s;
    }
    &.hide {
        transform: translateY(-100%);
    }
    .wp1340 {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .logo {
        img {
            max-height: 25px;
            width: auto;
            transition: all .4s;
        }
    }
    &-right {
        display: flex;
        align-items: center;
    }
    &-nav {
        margin-right: 60px;
        font-size: 0;
        li {
            display: inline-block;
            vertical-align: top;
            margin: 0 17px;
            &:hover {
                .nav-a {
                    &:after {
                        width: 100%;
                    }
                }
            }
        }
        .nav-a {
            position: relative;
            display: block;
            padding: 0 3px;
            height: 80px;
            line-height: 80px;
            font-size: 16px;
            color: #fffefe;
            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 0;
                height: 3px;
                background-color: #fff;
                transition: all .3s;
            }
        }
        .nav-sub {
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            background: rgba(0, 0, 0, .55);
            z-index: 99;
            &-wp {
                display: flex;
                justify-content: center;
                text-align: center;
            }
            a {
                display: inline-block;
                vertical-align: top;
                width: percentage(1/12);
                box-sizing: border-box;
                //margin: 0 52px;
                //min-width: 60px;
                padding: 30px 0 32px;
                &:hover {
                    .icon {
                        &-default {
                            display: none;
                        }
                        &-hover {
                            display: inline-block;
                        }
                    }
                    p {
                        color: #ad9462;
                    }
                }
            }
            .icon {
                margin-bottom: 10px;
                height: 30px;
                line-height: 30px;
                img {
                    vertical-align: middle;
                    max-width: 100%;
                    max-height: 100%;
                    transition: all .3s;
                }
                &-default {
                    display: inline-block;
                }
                &-hover {
                    display: none;
                }
            }
            p {
                line-height: 1.5;
                font-size: 14px;
                color: #fffefe;
                transition: all .3s;
            }
        }
    }
    &-search {
        &-btn {
            font-size: 20px;
            color: #f8fdff;
            cursor: pointer;
        }
        &-box {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            display: none;
            padding: 40px 0 50px;
            background: rgba(0, 0, 0, .55);
            z-index: 99;
            .wp1200 {
                display: flex;
                align-items: center;
            }
            form {
                display: flex;
                align-items: center;
                flex: 1;
                min-width: 0;
            }
            ::-webkit-input-placeholder {
                color: #fff;
            }
            ::-moz-placeholder {
                color: #fff;
            }
            :-ms-input-placeholder {
                color: #fff;
            }
            button,
            input {
                outline: none;
                background: none;
            }
            .icon {
                font-size: 24px;
                color: $baseColor;
            }
            .input {
                flex: 1;
                min-width: 0;
                margin: 0 70px 0 12px;
                border-bottom: 1px solid #aaa;
                input {
                    display: block;
                    width: 100%;
                    height: 42px;
                    line-height: 42px;
                    font-size: 16px;
                    color: #fff;
                    border: none;
                }
            }
            .close-btn {
                margin-left: 35px;
                font-size: 26px;
                color: $baseColor;
                transition: all .3s;
                cursor: pointer;
                &:hover {
                    transform: rotate(180deg);
                }
            }
            button {
                padding: 0 22px;
                height: 34px;
                line-height: 34px;
                font-size: 15px;
                color: $baseColor;
                border: 1px solid $baseColor;
                border-radius: 34px;
                cursor: pointer;
                &:hover {
                    color: #fff;
                    background-color: $baseColor;
                }
            }
        }
    }
    &-lang {
        position: relative;
        margin-left: 22px;
        padding-left: 22px;
        font-size: 18px;
        color: #f8fdff;
        i {
            margin-right: 6px;
            font-size: 18px;
        }
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -9px;
            height: 18px;
            width: 1px;
            background-color: #fff;
        }
    }
    @media screen and (max-width: 1440px) {
        &-nav {
            margin-right: 40px;
            li {
                margin: 0 15px;
            }
            //.nav-sub {
            //    a {
            //        margin: 0 30px;
            //    }
            //}
        }
    }
    @media screen and (max-width: 1200px) {
        .logo {
            img {
                max-height: 20px;
            }
        }
        &-nav {
            margin-right: 20px;
            li {
                margin: 0 10px;
            }
            .nav-a {
                font-size: 15px;
            }
            .nav-sub {
                a {
                    //margin: 0 40px;
                    //min-width: 60px;
                    padding: 20px 0 22px;
                }
                .icon {
                    height: 26px;
                    line-height: 26px;
                }
                p {
                    font-size: 13px;
                }
            }
        }
        &-search {
            &-btn {
                font-size: 17px;
            }
            &-box {
                padding: 35px 0 40px;
                .icon {
                    font-size: 22px;
                }
                .input {
                    margin-right: 50px;
                    input {
                        height: 38px;
                        line-height: 38px;
                        font-size: 15px;
                    }
                }
                .close-btn {
                    margin-left: 25px;
                }
            }
        }
        &-lang {
            margin-left: 16px;
            padding-left: 16px;
            font-size: 17px;
            i {
                font-size: 17px;
            }
            &:before {
                margin-top: -7px;
                height: 14px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        display: none;
    }
}

.header-slide {
    position: fixed;
    top: 0;
    left: 0;
    padding: 15px 0;
    width: 100%;
    background: rgba(32, 43, 51, .25);
    z-index: 998;
    .wp1340 {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .logo {
        img {
            max-height: 25px;
            width: auto;
        }
    }
    .menu-btn {
        height: 21px;
        color: #333;
        z-index: 1002;
        cursor: pointer;
        transition: all .3s;
        img {
            display: block;
            height: 100%;
            width: auto;
        }
    }
    @media screen and (max-width: 1024px) {
        display: none;
    }
}


.mobile-header {
    display: none;
    position: fixed;
    z-index: 9000;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0 px2rem(30px);
    height: px2rem(100px);
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 px2rem(4px) px2rem(12px) rgba(0, 0, 0, .1);
    box-sizing: border-box;
    &-block {
        display: none;
        height: px2rem(100px);
    }
    .logo {
        transform: translate(#{px2rem(-20px)});
        img {
            width: auto;
            max-height: px2rem(32px);
        }
    }
    .title {
        flex: 1;
        min-width: 0;
        margin: 0 px2rem(50px);
        font-size: px2rem(32px);
        color: $baseColor;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    &-menu,
    .search-btn {
        padding-top: px2rem(6px);
        line-height: px2rem(94px);
    }
    &-menu {
        color: $baseColor;
        font-size: px2rem(32px);
        font-family: 'iconfont';
        &:before {
            content: '\e610';
        }
        &.active {
            &:before {
                content: '\e629';
            }
        }
    }
    .search-btn {
        font-size: px2rem(36px);
        color: $baseColor;
    }
    &-search {
        display: none;
        position: fixed;
        top: px2rem(100px);
        left: 0;
        bottom: 0;
        transform: translateY(-100%);
        align-items: center;
        width: 100%;
        padding: 0 px2rem(30px) px2rem(80px);
        box-sizing: border-box;
        opacity: 0;
        background-color: rgba(0, 0, 0, .7);
        transition: transform .5s ease;
        z-index: 8889;
        &.anim {
            opacity: 1;
            transform: translateY(0);
        }
        .close-btn {
            position: absolute;
            top: px2rem(40px);
            right: px2rem(30px);
            color: $baseColor;
            font-size: px2rem(40px);
        }
        form {
            display: flex;
            align-items: center;
            width: 100%;
        }
        ::-webkit-input-placeholder {
            color: #fff;
        }
        ::-moz-placeholder {
            color: #fff;
        }
        :-ms-input-placeholder {
            color: #fff;
        }
        button,
        input {
            outline: none;
            background: none;
        }
        .icon {
            font-size: px2rem(32px);
            color: $baseColor;
        }
        .input {
            flex: 1;
            min-width: 0;
            margin: 0 px2rem(30px) 0 px2rem(15px);
            border-bottom: 1px solid #aaa;
            input {
                display: block;
                width: 100%;
                height: px2rem(80px);
                line-height: px2rem(80px);
                font-size: px2rem(26px);
                color: #fff;
                border: none;
            }
        }
        button {
            padding: 0 px2rem(32px);
            height: px2rem(60px);
            line-height: px2rem(60px);
            font-size: px2rem(28px);
            color: $baseColor;
            border: 1px solid $baseColor;
            border-radius: px2rem(70px);
            cursor: pointer;
            &:hover {
                color: #fff;
                background-color: $baseColor;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        display: flex;
        &-block {
            display: block;
        }
        &-search {
            display: flex;
        }
    }
}

.nav-mobile {
    position: fixed;
    top: px2rem(100px);
    left: 0;
    bottom: 0;
    width: px2rem(436px);
    transform: translateX(-100%);
    background-color: rgba(0, 0, 0, .7);
    box-sizing: border-box;
    overflow: hidden;
    transition: transform .5s ease;
    z-index: 6;
    &.anim {
        transform: translateX(0);
    }
    &-con {
        display: none;
        position: fixed;
        top: px2rem(100px);
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        z-index: 8889;
        transition: transform .4s ease;
        .bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .61);
            z-index: 1;
        }
    }
    &-box {
        padding: px2rem(6px) 0 px2rem(100px) px2rem(60px);
    }
    &-wp {
        position: relative;
        & > li {
            border-bottom: 1px solid #535353;
        }
    }
    &-title {
        position: relative;
        > a {
            position: relative;
            display: block;
            width: 100%;
            line-height: px2rem(90px);
            font-size: px2rem(28px);
            color: #fff;
            z-index: 2;
            backface-visibility: hidden;
            box-sizing: border-box;
        }
        &.open {
            .nav-mobile-child-btn i {
                &:before {
                    content: '\e630';
                }
            }
        }
    }
    &-child-btn {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-right: px2rem(30px);
        text-align: right;
        line-height: px2rem(90px);
        box-sizing: border-box;
        z-index: 1;
        i {
            font-size: px2rem(16px);
            color: #bfbfbf;
            font-family: "iconfont";
            transition: all .3s;
            &:before {
                content: "\e612";
            }
        }
        + a {
            display: inline-block;
            width: auto;
            max-width: calc(100% - .3rem);
        }
    }
    .sub {
        display: none;
        border-top: 1px solid #535353;
        li {
            position: relative;
            overflow: hidden;
            &:not(:last-child) a {
                border-bottom: 1px solid #535353;
            }
            a {
                display: block;
                line-height: px2rem(90px);
                height: px2rem(90px);
                font-size: px2rem(25px);
                color: #aaabab;
                overflow: hidden;
                box-sizing: border-box;
            }
        }
    }
    &-lang {
        margin-top: px2rem(120px);
        font-size: 0;
        a {
            display: inline-block;
            font-size: px2rem(28px);
            color: #b5b5b6;
            & + a {
                margin-left: px2rem(40px);
            }
            &.on,
            &:hover {
                color: $baseColor;
            }
        }
    }
}

