@charset 'utf-8';

/* CSS Document */
$baseColor: #0032a0;

//SCSS全局变量
// 默认移动端设计稿宽度
$baseDevice: 750;
// 前端切图移动端默认正常显示尺寸，默认为设计稿的一半
$device: $baseDevice / 2;
// 默认html font-size
$baseFontSize: 100px;
// scss function
@function calc-percent($target, $context) {
    @return $target/$context * 100%;
}

@function px2rem($px, $base-font-size: $baseDevice / $device * $baseFontSize) {
    @if (unitless($px)) {
        @warn "Assuming #{$px} to be in pixels, attempting to convert it into pixels for you";
        @return px2rem($px + 0px); // That may fail.
    }
    @else if (unit($px)==rem) {
        @return $px;
    }
    @return ($px / $base-font-size) * 1rem;
}

//透明
@mixin touming($o: 70) {
    opacity: $o/100;
    -webkit-opacity: $o/100;
    filter: alpha(opacity=$o);
}

@mixin transition($time: .2s) {
    -webkit-transition: $time ease all;
    -moz-transition: $time ease all;
    transition: $time ease all;
}

@mixin zoomImg($width, $height) {
    position: relative;
    padding-bottom: $height / $width * 100%;
    img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: .4s;
    }
}

@function px2vw($px) {
    @return ($px / 1920) * 100vw;
}
@mixin articleReset($fontsize: 14px, $lineHeight: 30px) {
    font-size: $fontsize;
    line-height: $lineHeight;
    p {
        margin-bottom: $lineHeight/2;
    }
    img,
    video {
        max-width: 100%;
        height: auto !important;
    }
    ul {
        list-style: disc;
        li {
            list-style: disc;
        }
    }
    ol {
        list-style: decimal;
        li {
            list-style: decimal;
        }
    }
}


/* reset.css V1.6  Start*/

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
dt,
dd,
ul,
ol,
li,
form,
button,
input,
textarea,
th,
td {
    font-weight: normal;
    margin: 0;
    padding: 0;
}

body,
button,
input,
select,
textarea,
h1,
h2,
h3,
h4,
h5,
h6,
i {
    font: normal 14px BlinkMacSystemFont, Helvetica Neue, Arial, PingFang SC, Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

ul,
ol,
li {
    list-style: none;
}

a {
    text-decoration: none;
    color: #333;
}

a:hover {
    text-decoration: none;
}

img {
    vertical-align: top;
    border: 0;
}

button,
input,
textarea {
    font-size: 100%;
    vertical-align: middle;
    outline: none;
    border-radius: 0;
    -webkit-appearance: none;
}

table {
    border-spacing: 0;
    border-collapse: collapse;
}

div,
a {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

html {
    font-size: $baseFontSize;
}

@media screen and (min-width: 320px) {
    html {
        font-size: (320/$device) * $baseFontSize;
    }
}

@media screen and (min-width: 360px) {
    html {
        font-size: (360/$device) * $baseFontSize;
    }
}

@media screen and (min-width: 375px) {
    html {
        font-size: (375/$device) * $baseFontSize;
    }
}

@media screen and (min-width: 480px) {
    html {
        font-size: (480/$device) * $baseFontSize;
    }
}

@media screen and (min-width: 640px) {
    html {
        font-size: (640/$device) * $baseFontSize;
    }
}

@media screen and (min-width: 750px) {
    html {
        font-size: (750/$device) * $baseFontSize;
    }
}

.clearfloat:after {
    font-size: 0;
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: ' ';
}

.clearfloat {
    *zoom: 1;
}

.clearboth {
    clear: both;
}

.wp {
    width: 1000px;
    margin: 0 auto;
}

.checkBrowser {
    font-size: 14px;
    line-height: 40px;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 50%;
    width: 700px;
    height: 40px;
    margin-left: -360px;
    padding: 0 10px;
    color: #fff;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #e6212a;
    a {
        font-size: 16px;
        line-height: 20px;
        position: absolute;
        top: 10px;
        right: 5px;
        display: block;
        width: 20px;
        height: 20px;
        cursor: pointer;
        text-align: center;
        color: #333;
        border-radius: 50%;
        background-color: #fff;
    }
}


/* reset.css V1.6  End */

.wd1340 {
    width: 1340px;
    margin: 0 auto;
    @media screen and (max-width: 1600px) {
        width: 1200px;
    }
    @media screen and (max-width: 1440px) {
        width: 1000px;
    }
    @media screen and (max-width: 1060px) {
        padding: 0 px2rem(35px);
        width: 100%;
        box-sizing: border-box;
    }
}

.wp1340 {
    max-width: 1340px;
    margin: 0 auto;
    @media screen and (max-width: 1440px) {
        //max-width: 100%;
        //padding: 0 50px;
        //box-sizing: border-box;
        max-width: 1200px;
    }
    @media screen and (max-width: 1260px) {
        padding: 0 px2rem(30px);
        max-width: 100%;
        box-sizing: border-box;
    }
}

.wp1140 {
    max-width: 1140px;
    margin: 0 auto;
    @media screen and (max-width: 1200px) {
        padding: 0 px2rem(30px);
        max-width: 100%;
        box-sizing: border-box;
    }
}

.wp1200 {
    max-width: 1200px;
    margin: 0 auto;
    @media screen and (max-width: 1260px) {
        max-width: 100%;
        padding: 0 px2rem(30px);
        box-sizing: border-box;
    }
}


@font-face {
    font-family: 'Gotham-Medium';
    src: url('../font/Gotham-Medium.eot');
    src: local('☺'), url('../font/Gotham-Medium.woff') format('woff'), url('../font/Gotham-Medium.ttf') format('truetype'), url('../font/Gotham-Medium.svg') format('svg');
}

@font-face {
    font-family: 'Gotham-Bold';
    src: url('../font/Gotham-Bold.eot');
    src: local('☺'), url('../font/Gotham-Bold.woff') format('woff'), url('../font/Gotham-Bold.ttf') format('truetype'), url('../font/Gotham-Bold.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GothamExLight';
    src: url('../font/GothamExLight.eot');
    src: local('☺'), url('../font/GothamExLight.woff') format('woff'), url('../font/GothamExLight.ttf') format('truetype'), url('../font/GothamExLight.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GothamCond-Bold';
    src: url('../font/GothamCond-Bold.eot');
    src: local('☺'), url('../font/GothamCond-Bold.woff') format('woff'), url('../font/GothamCond-Bold.ttf') format('truetype'), url('../font/GothamCond-Bold.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Condensed Bold';
    src: url('../font/Helvetica Condensed Bold.eot');
    src: local('☺'), url('../font/Helvetica Condensed Bold.woff') format('woff'), url('../font/Helvetica Condensed Bold.ttf') format('truetype'), url('../font/Helvetica Condensed Bold.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham-book';
    src: url('../font/Gotham-book.eot');
    src: local('☺'), url('../font/Gotham-book.woff') format('woff'), url('../font/Gotham-book.ttf') format('truetype'), url('../font/Gotham-book.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}


.inside-banner {
    position: relative;
    width: 100%;
    padding-bottom: (737 / 1920) * 100%;
    &-box {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        .img-pc,
        .img-mobile {
            width: 100%;
            height: 100%;
            background: #fff none no-repeat center center;
            background-size: cover;
        }
        .img-mobile {
            display: none;
        }
    }
    .inside-banner-pagination {
        position: absolute;
        left: 0;
        bottom: 35px;
        width: 100%;
        text-align: center;
        z-index: 9;
        .swiper-pagination-bullet {
            margin: 0 5px;
            width: 11px;
            height: 11px;
            border: 1px solid $baseColor;
            outline: none;
            background: none;
            opacity: 1;
            &-active {
                background-color: $baseColor;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .inside-banner-pagination {
            bottom: px2rem(25px);
        }
    }
    @media screen and (max-width: 750px) {
        padding-bottom: (427 / 750) * 100%;
        &.pro-banner {
            display: none;
        }
        &-box {
            .img-mobile {
                display: block;
            }
            .img-pc {
                display: none;
            }
        }
        .inside-banner-pagination {
            .swiper-pagination-bullet {
                width: 8px;
                height: 8px;
            }
        }
    }
}

.inside-menu {
    text-align: center;
    white-space: nowrap;
    background-color: #fff;
    &.fixed {
        .inside-menu-wp {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 999;
        }
        .inside-menu-once {
            li {
                a {
                    padding: 15px 0;
                }
            }
        }
        .inside-menu-sub {
            display: none;
        }
    }
    &-wp {
        width: 100%;
        background-color: rgba(255, 255, 255, .8);
        box-shadow: 0 px2rem(6px) px2rem(20px) rgba(0, 0, 0, .1);
        transition: all .3s;
    }
    &-once {
        &.no-init {
            .swiper-wrapper {
                display: block;
                width: 100%;
            }
        }
        .swiper-wrapper {
            font-size: 0;
        }
        li {
            position: relative;
            display: inline-block;
            vertical-align: top;
            width: auto;
            padding: 0 75px;
            &.on a,
            a:hover {
                .icon {
                    &-default {
                        display: none;
                    }
                    &-hover {
                        display: inline-block;
                    }
                }
                p {
                    color: $baseColor;
                }
            }
            a {
                display: block;
                padding: 26px 0;
                transition: all .3s;
            }
            .icon {
                height: 35px;
                line-height: 35px;
                img {
                    vertical-align: middle;
                    max-height: 100%;
                    max-width: 100%;
                }
                &-default {
                    display: inline-block;
                }
                &-hover {
                    display: none;
                }
            }
            p {
                margin-top: 6px;
                line-height: 1.5;
                font-size: 15px;
                color: #727171;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                transition: all .3s;
            }
        }
    }
    &-sub {
        height: 65px;
        line-height: 65px;
        border-top: 1px solid #dcdcdc;
        &.no-init {
            .swiper-wrapper {
                display: block;
                width: 100%;
            }
        }
        .swiper-wrapper {
            font-size: 0;
        }
        li {
            position: relative;
            display: inline-block;
            vertical-align: top;
            width: auto;
            padding: 0 84px;
            &.on a,
            a:hover {
                color: $baseColor;
            }
            a {
                display: block;
                font-size: 15px;
                color: #727171;
                transition: all .3s;
            }
        }
    }
    @media screen and (max-width: 1440px) {
        &.fixed {
            .inside-menu-once {
                li {
                    a {
                        padding: 12px 0;
                    }
                }
            }
        }
        &-once {
            li {
                padding: 0 60px;
                a {
                    padding: 18px 0;
                }
                .icon {
                    height: 32px;
                    line-height: 32px;
                }
            }
        }
        &-sub {
            height: 56px;
            line-height: 56px;
            li {
                padding: 0 64px;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        &-once {
            li {
                padding: 0 50px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        &.fixed {
            .inside-menu-wp {
                position: relative;
            }
            .inside-menu-once {
                li {
                    a {
                        padding: 0;
                    }
                }
            }
        }
        &-once {
            li {
                position: relative;
                padding: 0 30px;
                &:not(:last-child):after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 0;
                    margin-top: -6px;
                    width: 1px;
                    height: 12px;
                    background-color: #dcdcdc;
                }
                &.on a {
                    &:after {
                        opacity: 1;
                    }
                }
                a {
                    position: relative;
                    display: flex;
                    align-items: center;
                    padding: 0;
                    &:after {
                        content: '';
                        position: absolute;
                        bottom: -1px;
                        width: 100%;
                        height: 2px;
                        background-color: $baseColor;
                        opacity: 0;
                        transition: all .3s;
                    }
                }
                .icon {
                    margin-right: 8px;
                    height: 24px;
                    line-height: 24px;
                }
                p {
                    margin-top: 0;
                    line-height: 60px;
                }
            }
        }
        &-sub {
            li {
                position: relative;
                padding: 0 30px;
                &:not(:last-child):after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 0;
                    margin-top: -6px;
                    width: 1px;
                    height: 12px;
                    background-color: #dcdcdc;
                }
            }
        }
    }
    @media screen and (max-width: 750px) {
        &.fixed {
            .inside-menu-sub {
                height: 50px;
                line-height: 50px;
            }
        }
        &-once {
            li {
                padding: 0 15px;
                .icon {
                    margin-right: 6px;
                    height: 18px;
                    line-height: 18px;
                }
                p {
                    line-height: 50px;
                    font-size: 14px;
                }
            }
        }
        &-sub {
            height: 50px;
            line-height: 50px;
            li {
                padding: 0 15px;
                a {
                    font-size: 14px;
                }
            }
        }
    }
}

.list-page {
    padding: 120px 0 100px;
    overflow: hidden;
    @media screen and (max-width: 1200px) {
        padding: 100px 0 90px;
    }
    @media screen and (max-width: 1024px) {
        padding: 80px 0 70px;
    }
    @media screen and (max-width: 750px) {
        padding: px2rem(110px) 0 px2rem(100px);
    }
}

.article-page {
    padding: 90px 0;
    @media screen and (max-width: 1024px) {
        padding: 80px 0;
    }
    @media screen and (max-width: 750px) {
        padding: px2rem(100px) 0;
    }
}

.article-top {
    padding: 18px 0;
    background-color: #f7f7f7;
    border-bottom: 1px solid #cdcdcd;
    .wp1340 {
        display: flex;
        align-items: center;
    }
    &-btn {
        padding: 0 30px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        color: $baseColor;
        border: 1px solid $baseColor;
        border-radius: 32px;
        transition: all .3s;
        &:hover {
            color: #fff;
            background-color: $baseColor;
        }
    }
    @media screen and (max-width: 1024px) {
        padding: 15px 0;
        &-btn {
            padding: 0 20px;
            height: 26px;
            line-height: 26px;
            font-size: 13px;
        }
    }
    @media screen and (max-width: 750px) {
        display: none;
    }
}

.bread-con {
    flex: 1;
    min-width: 0;
    line-height: 32px;
    color: #727171;
    font-size: 14px;
    .icon {
        display: inline-block;
        vertical-align: top;
        margin: 2px 10px 0 0;
        height: 28px;
        img {
            height: 100%;
            width: auto;
        }
        &-default {
            display: block;
        }
        &-hover {
            display: none;
        }
    }
    a,
    span {
        display: inline-block;
        vertical-align: top;
        color: #727171;
        transition: all .3s;
        & + a,
        & + span {
            margin-left: 6px;
        }
    }
    a {
        &:after {
            content: '\e668';
            margin-left: 6px;
            font-family: 'iconfont';
            color: #bcb9ba;
            font-size: 13px;
        }
        &:hover {
            color: $baseColor;
            .icon {
                &-default {
                    display: none;
                }
                &-hover {
                    display: block;
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        line-height: 28px;
        font-size: 13px;
        .icon {
            margin-right: 6px;
            height: 24px;
        }
    }
}

// page结构以及CSS样式
.paged {
    font-size: 0;
    text-align: center;
}
.paged a,
.paged .p_info,
.paged .current {
    display: inline-block;
    vertical-align: top;
    margin: 5px 10px;
    padding: 0 10px;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    color: #595757;
    font-family: 'GothamExLight';
    transition: all .3s;
    @media screen and (max-width: 750px) {
        margin: 3px 5px;
        padding: 0 5px;
        font-size: 12px;
    }
}


.paged .current,
.paged a:hover {
    color: $baseColor;
    font-weight: bold;
}


// video button
.mejs__overlay-button {
    width: 69px;
    height: 69px;
    background-position: 0 -51px;
    outline: none;
    @media screen and (max-width: 750px) {
        width: 46px;
        height: 46px;
        background-position: 0 -34px;
        background-size: auto 80px;
    }
}

.mejs__overlay:hover > .mejs__overlay-button {
    background-position: -75px -51px;
    @media screen and (max-width: 750px) {
        background-position: -50px -34px;
    }
}

.article-swiper {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .8);
    touch-action: none;
    z-index: 9999;
    .swiper-pagination {
        bottom: 1em;
        color: #fff;
    }
    .swiper-slide {
        img {
            display: block;
            max-width: 95%;
            max-height: calc(100% - 6em);
        }
        p {
            position: absolute;
            top: 1em;
            left: 0;
            width: 100%;
            text-align: center;
            font-size: 16px;
            color: #fff;
            z-index: 22;
        }
    }
}
