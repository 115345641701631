.investment-page {
    padding: 50px 0;
    .wp1340 {
        display: flex;
    }
    &-left {
        flex: 1;
        min-width: 0;
    }
    &-right {
        padding: 180px 0 0 60px;
        width: 120px;
    }
    @media screen and (max-width: 1200px) {
        padding: 40px 0;
        &-right {
            padding-left: 40px;
            width: 100px;
        }
    }
    @media screen and (max-width: 1024px) {
        padding: px2rem(20px) 0;
        .wp1340 {
            position: relative;
            display: block;
        }
        &-right {
            position: absolute;
            top: 0;
            right: px2rem(30px);
            width: auto;
            padding: 150px 0 0;
        }
    }
    @media screen and (max-width: 750px) {
        &-right {
            padding-top: 130px;
        }
    }
}

.investment-anchor {
    position: relative;
    width: 120px;
    &.fixed {
        position: fixed;
        top: 50%;
        transform: translateY(-50%);
        z-index: 8888;
    }
    li {
        padding: 8px 20px;
        line-height: 24px;
        text-align: center;
        color: $baseColor;
        font-size: 15px;
        border: 1px solid $baseColor;
        background-color: #fff;
        border-radius: 42px;
        cursor: pointer;
        box-shadow: 0 px2rem(4px) px2rem(10px) rgba(0, 0, 0, .08);
        transition: all .3s;
        & + li {
            margin-top: 25px;
        }
        &.on {
            color: #fff;
            background-color: $baseColor;
        }
    }
    @media screen and (max-width: 1200px) {
        width: 100px;
        li {
            padding: 6px 20px;
            line-height: 22px;
            font-size: 14px;
            & + li {
                margin-top: 20px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        &.fixed {
            right: px2rem(30px);
        }
    }
    @media screen and (max-width: 750px) {
        width: 70px;
        li {
            padding: 4px 10px;
            line-height: 18px;
            font-size: 12px;
            & + li {
                margin-top: 10px;
            }
        }
    }
}

.investment-article {
    padding: 70px 0 80px;
    @include articleReset(14px, 30px);
    color: #666464;
    overflow: hidden;
    p {
        margin-bottom: 15px;
    }
    & + .investment-article,
    & + .investment-form {
        border-top: 1px solid #d6d6d6;
    }
    .img {
        padding: 10px 0;
        img {
            display: block;
            width: 100%;
        }
    }
    @media screen and (max-width: 1024px) {
        padding: 65px 0 70px;
    }
    @media screen and (max-width: 750px) {
        padding: px2rem(90px) 0 px2rem(100px);
        line-height: 28px;
        font-size: 13px;
        p {
            margin-bottom: 10px;
        }
    }
}

.investment-template-box {
    p {
        margin-bottom: 0;
    }
}

.investment-form {
    padding: 70px 0 80px;
    .normal-form {
        padding-top: 6px;
    }
    @media screen and (max-width: 1024px) {
        padding: 65px 0 70px;
    }
    @media screen and (max-width: 750px) {
        padding: px2rem(90px) 0 px2rem(100px);
    }
}

.investment-title {
    margin-bottom: 35px;
    line-height: 1.4;
    color: #221815;
    .en {
        font-size: 28px;
        text-transform: uppercase;
        font-family: 'Gotham-Bold';
    }
    .cn {
        font-size: 26px;
    }
    @media screen and (max-width: 1200px) {
        .en {
            font-size: 26px;
        }
        .cn {
            font-size: 24px;
        }
    }
    @media screen and (max-width: 1024px) {
        margin-bottom: 30px;
        .en {
            font-size: 24px;
        }
        .cn {
            font-size: 22px;
        }
    }
    @media screen and (max-width: 750px) {
        margin-bottom: 20px;
        .en {
            line-height: 1.3;
            font-size: 20px;
        }
        .cn {
            font-size: 20px;
        }
    }
}

.investment-infoimg {
    display: flex;
    &-info {
        flex: 1;
        min-width: 0;
        margin: -8px 60px 0 0;
        p {
            margin-bottom: 30px;
        }
    }
    &-img {
        order: 2;
        max-width: 40%;
    }
    @media screen and (max-width: 1024px) {
        display: block;
        &-img {
            padding: 10px 0 30px;
            max-width: 100%;
            img {
                width: 100%;
            }
        }
        &-info {
            margin: 0;
            p {
                margin-bottom: 20px;
            }
        }
    }
    @media screen and (max-width: 750px) {
        &-img {
            padding: 8px 0 20px;
        }
        &-info {
            p {
                margin-bottom: 15px;
            }
        }
    }
}

.investment-advantage {
    li {
        display: flex;
        & + li {
            margin-top: 26px;
        }
    }
    .icon {
        margin: 2px 15px 0 0;
        width: 30px;
        text-align: center;
        img {
            max-width: 100%;
        }
    }
    .right {
        flex: 1;
        min-width: 0;
        line-height: 28px;
        &-title {
            font-size: 16px;
            font-weight: bold;
        }
        p {
            margin-bottom: 0;
        }
    }
    @media screen and (max-width: 1024px) {
        li {
            & + li {
                margin-top: 20px;
            }
        }
        .icon {
            width: 26px;
        }
    }
    @media screen and (max-width: 750px) {
        li {
            & + li {
                margin-top: 15px;
            }
        }
        .icon {
            margin-right: 12px;
            width: 22px;
        }
        .right {
            &-title {
                font-size: 15px;
            }
        }
    }
}

.investment-advantage2 {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    margin: -10px 0;
    font-size: 15px;
    line-height: 25px;
    color: #666464;
    p {
        margin-bottom: 0;
    }
    li {
        padding: 18px 15px;
        width: 25%;
        list-style: none !important;
        box-sizing: border-box;
    }
    .box {
        margin: 0 auto;
        width: 140px;
        max-width: 100%;
    }
    .icon {
        margin: 0 auto 10px;
        width: 99px;
    }
    @media screen and (max-width: 1024px) {
        font-size: 14px;
        line-height: 24px;
        .icon {
            width: 80px;
        }
    }
    @media screen and (max-width: 750px) {
        li {
            padding: 14px px2rem(15px);
            width: 50%;
        }
        .icon {
            margin-bottom: 8px;
            width: 70px;
        }
    }
}

.investment-advantage3 {
    font-size: 15px;
    line-height: 32px;
    li {
        display: flex;
        list-style: none !important;
        & + li {
            margin-top: 15px;
        }
    }
    .icon {
        margin-right: 15px;
        width: 30px;
        line-height: inherit;
        text-align: center;
        img {
            display: inline-block;
            vertical-align: middle;
            margin-top: -2px;
            max-width: 100%;
        }
    }
    .right {
        flex: 1;
        min-width: 0;
    }
    @media screen and (max-width: 1024px) {
        line-height: 30px;
        font-size: 14px;
        li {
            & + li {
                margin-top: 12px;
            }
        }
        .icon {
            margin-right: 10px;
        }
    }
    @media screen and (max-width: 750px) {
        line-height: 28px;
        font-size: 13px;
        li {
            & + li {
                margin-top: 10px;
            }
        }
        .icon {
            width: 22px;
        }
    }
}

.investment-process {
    display: flex;
    flex-wrap: wrap;
    margin: -10px -20px 0 0;
    li {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 20px 20px 10px 0;
        min-width: calc(10% - 20px);
        text-align: center;
        padding: 24px 8px;
        font-size: 16px;
        line-height: 20px;
        color: $baseColor;
        border-radius: 4px;
        background-color: rgba($baseColor, .3);
        box-sizing: border-box;
        &:not(:last-child):after {
            content: '\e773';
            position: absolute;
            right: -20px;
            top: 50%;
            transform: translateY(-50%);
            width: 20px;
            text-align: center;
            font-family: 'iconfont';
            font-size: 15px;
            color: #aee0f8;
        }
    }
    @media screen and (max-width: 1200px) {
        li {
            min-width: 90px;
        }
    }
    @media screen and (max-width: 1024px) {
        li {
            font-size: 15px;
            line-height: 20px;
        }
    }
    @media screen and (max-width: 750px) {
        margin: -8px -15px 0 0;
        li {
            margin: 15px 15px 5px 0;
            min-width: 70px;
            padding: 15px 5px;
            width: calc(25% - 15px);
            font-size: 13px;
            line-height: 18px;
            &:not(:last-child):after {
                right: -15px;
                width: 15px;
                font-size: 12px;
            }
        }
    }
}

.investment-process2 {
    line-height: 32px;
    font-size: 15px;
    p {
        margin-bottom: 0;
    }
    li {
        display: flex;
        list-style: none !important;
    }
    .icon {
        margin-right: 10px;
        width: 14px;
        img {
            display: inline-block;
            vertical-align: middle;
            margin-top: -3px;
        }
    }
    .p {
        flex: 1;
        min-width: 0;
        color: #666464;
    }
    @media screen and (max-width: 1024px) {
        line-height: 30px;
        font-size: 14px;
    }
    @media screen and (max-width: 750px) {
        line-height: 28px;
        font-size: 13px;
    }
}

.investment-condition {
    p {
        margin-bottom: 0;
    }
}

.investment-article-tips {
    display: flex;
    padding-top: 12px;
    color: $baseColor;
    p {
        margin-bottom: 0;
    }
    .icon {
        margin-right: 8px;
        width: 17px;
        line-height: inherit;
        img {
            display: inline-block;
            vertical-align: middle;
            margin-top: -3px;
        }
    }
    .p {
        flex: 1;
        min-width: 0;
    }
    @media screen and (max-width: 750px) {
        padding-top: 10px;
        .icon {
            width: 15px;
        }
    }
}

.investment-condition-box {
    font-size: 15px;
    line-height: 32px;
    p {
        margin-bottom: 0;
    }
    & + .investment-condition-box {
        margin-top: 30px;
    }
    @media screen and (max-width: 1024px) {
        font-size: 14px;
        line-height: 30px;
    }
    @media screen and (max-width: 750px) {
        font-size: 13px;
        line-height: 28px;
        & + .investment-condition-box {
            margin-top: 20px;
        }
    }
}

.investment-condition-table {
    padding-top: 10px;
    line-height: 22px;
    table {
        width: 100%;
        table-layout: fixed;
        text-align: center;
        border-collapse: collapse;
    }
    td,
    th {
        padding: 10px 15px;
        border: 1px solid #bfbfbf;
    }
    .text-alignleft {
        text-align: left;
    }
    .td-span {
        display: inline-block;
        vertical-align: top;
        &:not(:last-child) {
            margin-right: 30px;
        }
    }
    @media screen and (max-width: 1024px) {
        line-height: 20px;
        .td-span {
            &:not(:last-child) {
                margin-right: 20px;
            }
        }
    }
    @media screen and (max-width: 750px) {
        line-height: 18px;
        td,
        th {
            padding: 8px px2rem(15px);
        }
        .td-span {
            display: block;
            &:not(:last-child) {
                margin-right: 0;
            }
        }
    }
}