.recruit-menu {
    margin: 0 -70px;
    padding: 15px 0 45px;
    font-size: 0;
    text-align: center;
    a {
        display: inline-block;
        vertical-align: top;
        margin: 0 70px;
        height: 38px;
        line-height: 38px;
        padding: 0 px2rem(20px);
        min-width: 80px;
        text-align: center;
        font-size: 15px;
        color: $baseColor;
        border-radius: 40px;
        border: 1px solid $baseColor;
        transition: all .3s;
        &:hover,
        &.on {
            color: #fff;
            background-color: $baseColor;
        }
    }
    @media screen and (max-width: 1440px) {
        margin: 0 -60px;
        a {
            margin: 0 60px;
        }
    }
    @media screen and (max-width: 1200px) {
        margin: 0 -50px;
        padding: 5px 0 35px;
        a {
            margin: 0 50px;
            height: 35px;
            line-height: 35px;
        }
    }
    @media screen and (max-width: 1024px) {
        margin: 0 -36px;
        a {
            margin: 0 36px;
        }
    }
    @media screen and (max-width: 750px) {
        margin: 0 px2rem(-15px);
        padding-bottom: 30px;
        a {
            margin: 0 px2rem(15px);
            height: 30px;
            line-height: 30px;
            font-size: 14px;
            min-width: 70px;
        }
    }
}

.recruit-box {
    .name,
    .department {
        width: 13%
    }
    .company {
        width: 18%;
    }
    .num,
    .education {
        width: 10%;
    }
    .address,
    .date {
        width: 12%;
    }
    .more {
        flex: 1;
        min-width: 0;
        color: #c9caca;
        .iconfont {
            font-size: 20px;
            transform: rotate(180deg);
            transition: all .3s;
        }
    }
    &-hd {
        display: flex;
        height: 50px;
        line-height: 50px;
        font-size: 16px;
        color: #fff;
        background-color: #acacac;
        border-bottom: 1px solid #cbcbcb;
        span {
            padding-left: 46px;
            box-sizing: border-box;
        }
    }
    @media screen and (max-width: 1440px) {
        &-hd {
            span {
                padding-left: 40px;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        &-hd {
            height: 45px;
            line-height: 45px;
            font-size: 15px;
            span {
                padding-left: 30px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .more {
            flex: 0 0 auto;
            .iconfont {
                font-size: 18px;
            }
        }
        .name {
            flex: 1;
            min-width: 0;
        }
        &-hd {
            text-align: center;
            border-radius: 5px;
            font-size: 17px;
            span {
                padding-left: 0;
            }
            .more,
            .department,
            .company,
            .num,
            .education,
            .address,
            .date {
                display: none;
            }
        }
    }
}

.recruit-list {
    .item {
        border-bottom: 1px solid #cbcbcb;
        &-hd {
            display: flex;
            line-height: 22px;
            font-size: 15px;
            color: #727171;
            background-color: #fff;
            cursor: pointer;
            transition: all .3s;
            li {
                display: flex;
                align-items: center;
                padding: 13px 0 13px 46px;
                min-height: 70px;
                box-sizing: border-box;
            }
            .more {
                padding-right: 36px;
                justify-content: flex-end;
            }
            &:hover,
            &.active {
                color: $baseColor;
                .more {
                    color: $baseColor;
                }
            }
            &.active {
                .more .iconfont {
                    transform: rotate(0);
                }
            }
        }
        &-bd {
            display: none;
            padding: 26px 46px 35px;
            line-height: 28px;
            font-size: 14px;
            color: #727171;
            background-color: #f7f8f8;
            border-top: 1px dashed #d3d3d3;
            &-params {
                display: none;
                margin-bottom: 20px;
                color: #4c4948;
                li {
                    display: flex;
                }
                p {
                    flex: 1;
                    min-width: 0;
                }
            }
            &-article {
                overflow: hidden;
            }
        }
    }
    .email-btn {
        display: inline-block;
        vertical-align: top;
        margin-top: 40px;
        padding: 0 60px;
        line-height: 38px;
        height: 38px;
        color: $baseColor;
        font-size: 16px;
        border: 1px solid $baseColor;
        border-radius: 40px;
        transition: all .3s;
        &:hover {
            color: #fff;
            background-color: $baseColor;
        }
    }
    @media screen and (max-width: 1440px) {
        .item {
            &-hd {
                li {
                    padding-left: 40px;
                }
            }
            &-bd {
                padding-left: 40px;
                padding-right: 40px;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        .item {
            &-hd {
                font-size: 14px;
                li {
                    padding: 9px 0 9px 30px;
                    min-height: 62px;
                }
                .more {
                    padding-right: 26px;
                }
            }
            &-bd {
                padding: 20px px2rem(30px) 30px;
            }
        }
        .email-btn {
            margin-top: 30px;
            padding: 0 50px;
            line-height: 35px;
            height: 35px;
            font-size: 15px;
        }
    }
    @media screen and (max-width: 1024px) {
        padding-top: px2rem(10px);
        .item {
            border: 1px solid #eee;
            border-radius: 5px;
            margin-top: px2rem(20px);
            &-hd {
                padding: 12px px2rem(20px) 12px px2rem(30px);
                li {
                    padding: 0;
                    min-height: 0;
                    font-size: 15px;
                }
                .more {
                    margin-left: px2rem(30px);
                    padding-right: 0;
                }
                .department,
                .company,
                .num,
                .education,
                .address,
                .date {
                    display: none;
                }
            }
            &-bd {
                border-top: none;
                &-params {
                    display: block;
                }
            }
        }
    }
    @media screen and (max-width: 750px) {
        .item {
            &-bd {
                padding-bottom: 25px;
                line-height: 26px;
                font-size: 13px;
                &-params {
                    margin-bottom: 12px;
                }
            }
        }
        .email-btn {
            display: block;
            margin-top: 18px;
            padding: 0 px2rem(20px);
            line-height: 30px;
            height: 30px;
            font-size: 14px;
            text-align: center;
        }
    }
}

.recruit-process {
    padding: 85px 0 95px;
    background-color: #f8f8f8;
    &-list {
        position: relative;
        display: flex;
        justify-content: space-between;
        text-align: center;
        &:before {
            content: '';
            position: absolute;
            left: 30px;
            right: 30px;
            top: 113px;
            border-bottom: 1px dashed $baseColor;
        }
        li {
            position: relative;
            list-style: none !important;
            z-index: 2;
        }
        .num {
            display: block;
            margin-bottom: 36px;
            height: 44px;
            line-height: 1;
            font-size: 56px;
            color: rgba($baseColor, .3);
            font-family: 'Helvetica Condensed Bold';
            overflow: hidden;
        }
        .icon {
            margin: 0 auto 10px;
            width: 66px;
            height: 66px;
            img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        p {
            margin-bottom: 0;
            line-height: 1.5;
            font-size: 16px;
            color: #000;
            font-weight: bold;
        }
    }
    @media screen and (max-width: 1200px) {
        padding: 75px 0 85px;
        &-list {
            &:before {
                top: 98px;
            }
            .num {
                margin-bottom: 30px;
                height: 38px;
                font-size: 50px;
            }
            .icon {
                width: 60px;
                height: 60px;
            }
            p {
                font-size: 15px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        padding: 65px 0 75px;
        &-list {
            &:before {
                top: 75px;
            }
            .num {
                margin-bottom: 20px;
                height: 30px;
                font-size: 40px;
            }
            .icon {
                width: 50px;
                height: 50px;
            }
            p {
                font-size: 14px;
            }
        }
    }
    @media screen and (max-width: 750px) {
        padding: px2rem(100px) 0 px2rem(110px);
        &-list {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            li {
                position: relative;
                & + li {
                    margin-top: px2rem(60px);
                }
            }
            &:before {
                top: 20px;
                bottom: 60px;
                left: 50%;
                right: auto;
                transform: translateX(-50%);
                border-right: 1px dashed $baseColor;
                border-bottom: none;
            }
            .num {
                position: absolute;
                margin-bottom: 0;
                right: calc(100% + 15px);
                line-height: 66px;
                height: auto;
                font-size: 52px;
            }
            .icon {
                width: 66px;
                height: 66px;
            }
            p {
                font-size: 15px;
            }
        }
    }
}


.career-article {
    padding: 110px 0 120px;
    &-box {
        & + .career-article-box {
            margin-top: 120px;
            padding-top: 110px;
            border-top: 1px solid #cbcbcb;
        }
    }
    .img-group3 {
        display: flex;
        justify-content: space-between;
        max-width: 1030px;
        margin: 0 auto;
        li {
            list-style: none !important;
        }
    }
    .img-pc,
    .img-mobile {
        text-align: center;
    }
    .img-mobile {
        display: none;
    }
    @media screen and (max-width: 1200px) {
        padding: 90px 0 100px;
        &-box {
            & + .career-article-box {
                margin-top: 100px;
                padding-top: 90px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        padding: 70px 0 80px;
        &-box {
            & + .career-article-box {
                margin-top: 80px;
                padding-top: 70px;
            }
        }
        .img-group3 {
            li img {
                max-width: 138px;
            }
        }
    }
    @media screen and (max-width: 750px) {
        padding: px2rem(100px) 0 px2rem(110px);
        &-box {
            & + .career-article-box {
                margin-top: px2rem(110px);
                padding-top: px2rem(100px);
            }
        }
        .img-group3 {
            display: block;
            text-align: center;
            li {
                & + li {
                    margin-top: px2rem(70px);
                }
            }
        }
        .img-mobile {
            display: block;
        }
        .img-pc {
            display: none
        }
    }
}


.career-salary-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 px2rem(-30px);
    color: #898989;
    li {
        padding: 0 px2rem(30px);
        width: (100% / 3);
        text-align: center;
        list-style: none !important;
        box-sizing: border-box;
        &:nth-child(n+4) {
            margin-top: 100px;
        }
    }
    .icon {
        margin: 0 auto;
        width: 99px;
        img {
            display: block;
            width: 100%;
        }
    }
    .title {
        margin: 18px 0 10px;
        &-cn {
            font-weight: bold;
            font-size: 18px;
            color: #000;
        }
        &-en {
            margin-top: 2px;
            line-height: 1.2;
            font-size: 14px;
            color: #101010;
            font-family: 'GothamExLight';
        }
    }
    @media screen and (max-width: 1200px) {
        li {
            &:nth-child(n+4) {
                margin-top: 85px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        li {
            &:nth-child(n+4) {
                margin-top: 60px;
            }
        }
        .icon {
            width: 82px;
        }
        .title {
            margin: 15px 0 8px;
            &-cn {
                font-size: 16px;
            }
            &-en {
                font-size: 12px;
            }
        }
    }
    @media screen and (max-width: 750px) {
        li {
            width: 50%;
            &:nth-child(n+3) {
                margin-top: 40px;
            }
        }
        .icon {
            width: 70px;
        }
    }
}

.environment-gallery {
    padding: 90px 0;
    background-color: #eee;
    &-wp {
        position: relative;
        padding: 0 90px;
    }
    &-swiper {
        .swiper-slide {
            &:hover {
                .img img {
                    transform: scale(1.1);
                }
            }
            .img {
                position: relative;
                margin-bottom: 20px;
                width: 100%;
                height: 0;
                padding-bottom: (264 / 355) * 100%;
                overflow: hidden;
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    transition: all .5s;
                }
            }
            .title {
                margin-bottom: 3px;
                line-height: 26px;
                font-size: 18px;
                color: #181818;
            }
            .p {
                font-size: 14px;
                line-height: 24px;
                color: #727171;
            }
        }
    }
    .gallery-prev,
    .gallery-next {
        position: absolute;
        top: 132px;
        font-size: 26px;
        color: $baseColor;
        outline: none;
        cursor: pointer;
        transition: all .3s;
        z-index: 9;
        &.swiper-button-disabled {
            color: #c9caca;
        }
    }
    .gallery-prev {
        left: 0;
        transform: translateY(-50%) rotate(180deg);
    }
    .gallery-next {
        right: 0;
        transform: translateY(-50%);
    }
    .gallery-pagination {
        display: none;
        margin-top: 20px;
        text-align: center;
        .swiper-pagination-bullet {
            margin: 0 4px;
            width: 6px;
            height: 6px;
            border: 1px solid $baseColor;
            outline: none;
            background: none;
            opacity: 1;
            &-active {
                background-color: $baseColor;

            }
        }
    }
    @media screen and (max-width: 1440px) {
        .gallery-prev,
        .gallery-next {
            top: 115px;
        }
    }
    @media screen and (max-width: 1200px) {
        padding: 80px 0;
        &-wp {
            padding: 0 60px;
        }
        &-swiper {
            .swiper-slide {
                .img {
                    margin-bottom: 16px;
                }
                .title {
                    line-height: 24px;
                    font-size: 16px;
                }
                .p {
                    font-size: 13px;
                    line-height: 22px;
                }
            }
        }
        .gallery-prev,
        .gallery-next {
            top: calc((100vw - 250px) / 3 * (264 / 355) / 2);
        }
    }
    @media screen and (max-width: 1024px) {
        padding: 70px 0;
        &-wp {
            padding: 0 40px;
        }
        .gallery-prev,
        .gallery-next {
            top: calc((100vw - 130px) / 2 * (264 / 355) / 2);
            font-size: 22px;
        }
    }
    @media screen and (max-width: 750px) {
        padding: px2rem(40px) 0 px2rem(100px);
        &-wp {
            padding: 0;
        }
        .gallery-prev,
        .gallery-next {
            top: calc((100vw - .3rem) * (264 / 355) / 2);
            font-size: px2rem(36px);
        }
        .gallery-prev {
            left: px2rem(20px);
        }
        .gallery-next {
            right: px2rem(20px);
        }
        .gallery-pagination {
            display: block;
        }
    }
}


.staff-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -9px 50px;
    li {
        padding: 0 9px 60px;
        width: 20%;
        box-sizing: border-box;
    }
    .box {
        cursor: pointer;
        &-top {
            position: relative;
        }
        &:hover {
            .info {
                opacity: 1;
                &-p {
                    opacity: 1;
                    transition: all .3s .2s;
                }
            }
            .more {
                color: #fff;
                background-color: $baseColor;
            }
        }
    }
    .img {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: (282 / 253) * 100%;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .info {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 18px;
        line-height: 1.4;
        text-align: center;
        background-color: rgba(0, 0, 0, .7);
        box-sizing: border-box;
        overflow: hidden;
        opacity: 0;
        z-index: 2;
        transition: all .3s;
        &-name {
            font-size: 25px;
            color: $baseColor;
            font-weight: bold;
        }
        &-tag {
            font-size: 18px;
            color: #fff;
        }
        &-p {
            margin-top: 24px;
            font-size: 15px;
            color: #fff;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            opacity: 0;
            transition: all .3s;
            &:before {
                content: '';
                display: block;
                margin: 0 auto 15px;
                width: 1px;
                height: 50px;
                background-color: #fff;
            }
        }
    }
    .more {
        margin: 36px auto 0;
        width: 150px;
        height: 38px;
        text-align: center;
        line-height: 38px;
        font-size: 14px;
        color: $baseColor;
        border: 1px solid $baseColor;
        border-radius: 40px;
        transition: all .4s;
    }
    .content {
        display: none;
    }
    @media screen and (max-width: 1440px) {
        .info {
            &-name {
                font-size: 23px;
            }
            &-tag {
                font-size: 17px;
            }
            &-p {
                margin-top: 16px;
                font-size: 14px;
                &:before {
                    margin-bottom: 12px;
                    height: 45px;
                }
            }
        }
        .more {
            width: 135px;
            height: 35px;
            line-height: 35px;
            font-size: 14px;
        }
    }
    @media screen and (max-width: 1200px) {
        margin-bottom: 40px;
        li {
            padding-bottom: 50px;
            width: 25%;
        }
        .more {
            margin-top: 30px;
        }
    }
    @media screen and (max-width: 1024px) {
        li {
            width: (100% / 3);
        }
    }
    @media screen and (max-width: 750px) {
        margin: 0 px2rem(-15px) px2rem(20px);
        li {
            padding: 0 px2rem(15px) px2rem(80px);
            width: 50%;
        }
        .box-top {
            border-bottom: 1px solid #d2d2d2;
        }
        .info {
            position: relative;
            display: block;
            padding: 8px 0 6px;
            height: auto;
            text-align: left;
            background: none;
            opacity: 1;
            &-name {
                font-size: 18px;
                color: #000;
            }
            &-tag {
                font-size: 16px;
                color: #000;
            }
            &-p {
                margin-top: 5px;
                font-size: 13px;
                color: #666464;
                opacity: 1;
                &:before {
                    display: none;
                }
            }
        }
        .more {
            margin-top: 15px;
            width: 100px;
            height: 28px;
            line-height: 28px;
            font-size: 12px;
        }
    }
}

// 弹框
.staff-popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    z-index: 9000;
    &.show {
        display: flex;
    }
    &.show-ani {
        .staff-popup-box,
        .staff-popup-mask {
            opacity: 1;
        }
        .staff-popup-box {
            transition-delay: .2s;
        }
        .staff-popup-mask {
            transition-delay: 0s;
        }
    }
    &-box {
        position: relative;
        width: 1340px;
        padding: 55px 60px 60px;
        background-color: #555;
        box-sizing: border-box;
        opacity: 0;
        z-index: 6;
        transition: all .4s;
    }
    &-mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .9);
        opacity: 0;
        z-index: 1;
        transition: all .3s .2s;
    }
    &-wp {
        display: flex;
        flex-direction: column;
        min-height: 50vh;
        max-height: calc(100vh - 300px);
    }
    .scroller {
        position: relative;
        width: 100%;
        flex: 1;
        overflow: hidden;
    }
    .bscroll-vertical-scrollbar {
        width: 5px !important;
        border-radius: 5px;
        background-color: #313131;
        z-index: 887 !important;
    }
    .bscroll-indicator {
        background-color: $baseColor !important;
        border-color: $baseColor !important;
    }
    &-close {
        position: absolute;
        top: 30px;
        right: 40px;
        font-size: 30px;
        color: $baseColor;
        z-index: 1;
        cursor: pointer;
        transition: all .3s;
        &:hover {
            transform: rotate(180deg);
        }
    }
    &-top {
        margin-bottom: 40px;
        &:after {
            content: '';
            display: block;
            margin-top: 30px;
            width: 42px;
            height: 1px;
            background-color: #c9c9c9;
        }
        .title {
            margin-bottom: 6px;
            img {
                width: auto;
                height: 36px;
            }
        }
        .p {
            line-height: 1.5;
            font-size: 22px;
            color: #fff;
        }
        .staff {
            margin-top: 3px;
            line-height: 1.5;
            font-size: 15px;
            color: #dcdddd;
        }
    }
    &-content {
        padding-right: 30px;
        @include articleReset(15px, 26px);
        color: #c9caca;
        p {
            margin-bottom: 0;
        }
    }
    @media screen and (max-width: 1440px) {
        &-box {
            width: 1200px;
        }
        &-wp {
            max-height: calc(100vh - 240px);
        }
        &-top {
            .title {
                img {
                    height: 30px;
                }
            }
        }
    }
    @media screen and (max-width: 1260px) {
        &-box {
            width: calc(100% - .3rem);
        }
        &-content {
            font-size: 14px;
        }
    }
    @media screen and (max-width: 1024px) {
        &-box {
            padding: 55px px2rem(50px) 60px;
        }
        &-wp {
            max-height: calc(100vh - 200px);
        }
        &-close {
            right: 25px;
        }
        &-top {
            margin-bottom: 30px;
            &:after {
                margin-top: 25px;
            }
            .title {
                img {
                    height: 26px;
                }
            }
            .p {
                font-size: 20px;
            }
            .staff {
                font-size: 14px;
            }
        }
    }
    @media screen and (max-width: 750px) {
        &-box {
            padding: px2rem(120px) px2rem(20px) px2rem(80px) px2rem(50px);
        }
        .bscroll-vertical-scrollbar {
            width: 3px !important;
        }
        &-wp {
            max-height: calc(100vh - 2rem);
        }
        &-close {
            top: px2rem(40px);
            right: px2rem(40px);
            font-size: 22px;
        }
        &-top {
            margin-bottom: 25px;
            padding-right: px2rem(30px);
            &:after {
                margin-top: 15px;
                width: 32px;
            }
            .title {
                img {
                    height: 22px;
                }
            }
            .p {
                font-size: 18px;
            }
            .staff {
                margin-top: 0;
            }
        }
        &-content {
            padding-right: px2rem(30px);
            line-height: 24px;
            font-size: 13px;
        }
    }
}