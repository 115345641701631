.pro-title {
    margin-bottom: 55px;
    text-align: center;
    h2 {
        line-height: 1.4;
        font-size: 33px;
        color: #000;
    }
    p {
        line-height: 1.4;
        font-size: 15px;
        color: #9fa0a0;
        font-family: 'GothamExLight';
    }
    @media screen and (max-width: 1440px) {
        h2 {
            font-size: 30px;
        }
    }
    @media screen and (max-width: 1200px) {
        margin-bottom: 45px;
        h2 {
            font-size: 28px;
        }
        p {
            font-size: 14px;
        }
    }
    @media screen and (max-width: 1024px) {
        margin-bottom: 40px;
        h2 {
            font-size: 24px;
        }
        p {
            font-size: 13px;
        }
    }
    @media screen and (max-width: 750px) {
        margin-bottom: 25px;
        h2 {
            font-size: 20px;
        }
        p {
            font-size: 12px;
        }
    }
}

.pro-gallery {
    position: relative;
    margin-bottom: 80px;
    padding-right: 340px;
    &-big {
        flex: 1;
        min-width: 0;
        .swiper-container {
            border-radius: 8px;
        }
        .swiper-slide {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            overflow: hidden;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
    &-thumbs {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        width: 230px;
        &-box {
            flex: 1;
            min-height: 0;
            width: 100%;
            .swiper-slide {
                border-radius: 8px;
                border: 4px solid transparent;
                overflow: hidden;
                box-sizing: border-box;
                cursor: pointer;
                transition: all .3s;
                &-thumb-active {
                    border-color: rgba($baseColor, .6);
                    .img {
                        border-color: #d0d0d0;
                    }
                }
            }
            .img {
                display: block;
                height: 100%;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
            }
        }
        .thumbs-next,
        .thumbs-prev {
            height: 50px;
            text-align: center;
            font-size: 26px;
            color: $baseColor;
            outline: none;
            cursor: pointer;
            &.swiper-button-disabled {
                color: #d2d2d2;
                cursor: default;
            }
            &:before {
                position: relative;
                display: inline-block;
            }
        }
        .thumbs-prev {
            &:before {
                transform: rotate(-90deg);
            }
        }
        .thumbs-next {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            &:before {
                transform: rotate(90deg);
            }
        }
    }
    @media screen and (max-width: 1200px) {
        margin-bottom: 70px;
        padding-right: 300px;
    }
    @media screen and (max-width: 1024px) {
        padding-right: 0;
        &-thumbs {
            position: relative;
            flex-direction: row;
            margin-top: 25px;
            width: 100%;
            &-box {
                min-width: 0;
                .img {
                    height: 0;
                    padding-bottom: 100%;
                }
            }
            .thumbs-next,
            .thumbs-prev {
                display: flex;
                align-items: center;
                text-align: left;
                width: 40px;
                height: auto;
                font-size: 22px;
            }
            .thumbs-prev {
                &:before {
                    transform: rotate(-180deg);
                }
            }
            .thumbs-next {
                justify-content: flex-end;
                &:before {
                    transform: rotate(0);
                }
            }
        }
    }
    @media screen and (max-width: 750px) {
        margin-bottom: px2rem(80px);
        &-thumbs {
            margin-top: 16px;
            .thumbs-next,
            .thumbs-prev {
                width: 25px;
                font-size: 16px;
            }
            &-box {
                .swiper-slide {
                    border-width: 3px;
                }
            }
        }
    }
}

.pro-detail {
    &-title {
        margin-bottom: 26px;
        line-height: 1.3;
        font-size: 20px;
        color: #3c3c3c;
        span {
            display: inline-block;
            vertical-align: top;
        }
        .en {
            margin-left: 5px;
        }
        &-border {
            padding-bottom: 16px;
            border-bottom: 1px solid #d2d2d2;
        }
    }
    & + .pro-detail {
        margin-top: 90px;
    }
    @media screen and (max-width: 1200px) {
        & + .pro-detail {
            margin-top: 80px;
        }
    }
    @media screen and (max-width: 750px) {
        &-title {
            margin-bottom: 15px;
            font-size: 18px;
            span {
                display: block;
            }
            .en {
                margin-left: 0;
                font-size: 18px;
            }
            &-border {
                padding-bottom: 10px;
            }
        }
        & + .pro-detail {
            margin-top: px2rem(90px);
        }
    }
}

.pro-article {
    @include articleReset(14px, 28px);
    color: #717171;
    p {
        margin-bottom: 12px;
    }
    .img {
        margin-bottom: 30px;
        img {
            width: 100%;
        }
    }
    @media screen and (max-width: 750px) {
        display: none;
    }
}

.pro-article-mobile {
    display: none;
    @include articleReset(13px, 28px);
    color: #717171;
    p {
        margin-bottom: 10px;
    }
    .img {
        margin-bottom: 20px;
        img {
            width: 100%;
        }
    }
    @media screen and (max-width: 750px) {
        display: block;
    }
}

.pro-spec-table {
    table {
        width: 100%;
        line-height: 22px;
        text-align: center;
        table-layout: fixed;
        border-collapse: collapse;
        p {
            margin-bottom: 0;
        }
    }
    th {
        padding: 24px 5px 20px;
        font-size: 15px;
        color: #727171;
        background-color: #f1f1f1;
    }
    td {
        padding: 12px 5px;
        font-size: 14px;
        color: #898989;
        border-bottom: 1px solid #d2d2d2;
    }
    @media screen and (max-width: 750px) {
        table {
            line-height: 18px;
        }
        th {
            padding: 16px 5px 12px;
            font-size: 14px;
            .en {
                display: none;
            }
        }
        td {
            line-height: 16px;
            padding: 10px 2px;
            font-size: 12px;
        }
    }
}

.pro-instuction-img {
    display: flex;
    align-items: flex-end;
    padding-top: 10px;
    .img-big {
        width: (680 / 1140) * 100%;
    }
    .img-flex {
        margin-left: 25px;
        flex: 1;
        min-width: 0;
    }
    @media screen and (max-width: 750px) {
        display: block;
        .img-big {
            width: 100%;
        }
        .img-flex {
            margin-left: 0;
        }
    }
}


.pro-menu {
    text-align: center;
    white-space: nowrap;
    background-color: #fff;
    &.fixed {
        .pro-menu-wp {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 999;
        }
        .swiper-slide {
            a {
                padding: 15px 0;
            }
        }
    }
    &-wp {
        position: relative;
        width: 100%;
        background-color: rgba(255, 255, 255, .8);
        box-shadow: 0 px2rem(6px) px2rem(20px) rgba(0, 0, 0, .1);
        box-sizing: border-box;
        transition: all .3s;
    }
    .swiper-container.no-init {
        .swiper-wrapper {
            width: 100%;
            justify-content: center;
        }
        & ~ .pro-menu-prev,
        & ~ .pro-menu-next {
            display: none;
        }
    }
    .swiper-container {
        padding: 0 40px;
    }
    .swiper-wrapper {
        font-size: 0;
    }
    .swiper-slide {
        position: relative;
        display: inline-block;
        vertical-align: top;
        width: auto;
        padding: 0 20px;
        &.on a,
        a:hover {
            .icon {
                &-default {
                    display: none;
                }
                &-hover {
                    display: inline-block;
                }
            }
            p {
                color: $baseColor;
            }
        }
        a {
            display: block;
            padding: 26px 0;
            transition: all .3s;
            .mb {
                display: none;
            }
        }
        .icon {
            height: 35px;
            line-height: 35px;
            img {
                vertical-align: middle;
                max-height: 100%;
                max-width: 100%;
            }
            &-default {
                display: inline-block;
            }
            &-hover {
                display: none;
            }
        }
        p {
            margin-top: 9px;
            line-height: 1.4;
            font-size: 14px;
            color: #727171;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            transition: all .3s;
        }
    }
    &-prev,
    &-next {
        position: absolute;
        top: 50%;
        width: 40px;
        text-align: center;
        font-size: 24px;
        color: $baseColor;
        z-index: 99;
        outline: none;
        cursor: pointer;
        transition: all .3s;
        &.swiper-button-disabled {
            color: #c9caca;
        }
    }
    &-prev {
        left: 0;
        transform: translateY(-50%) rotate(-180deg);
    }
    &-next {
        right: 0;
        transform: translateY(-50%);
    }
    @media screen and (max-width: 1440px) {
        &.fixed {
            .swiper-slide {
                a {
                    padding: 12px 0;
                }
            }
        }
        .swiper-slide {
            a {
                padding: 18px 0;
            }
            .icon {
                height: 32px;
                line-height: 32px;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        .swiper-container {
            padding: 0 35px;
        }
        .swiper-slide {
            padding: 0 16px;
            p {
                font-size: 13px;
            }
        }
        &-prev,
        &-next {
            width: 35px;
            font-size: 20px;
        }
    }
    @media screen and (max-width: 750px) {
        position: fixed;
        top: px2rem(100px);
        left: 0;
        width: 100%;
        text-align: left;
        white-space: normal;
        border-bottom: 1px solid #dcdcdc;
        z-index: 8888;
        &.fixed {
            .pro-menu-wp {
                position: relative;
            }
            .swiper-slide {
                a {
                    padding: 0;
                }
            }
        }
        &-wp {
            padding: 5px px2rem(50px);
            box-shadow: none;
            overflow: hidden;
            &:before {
                content: '';
                position: absolute;
                display: none;
                top: 50%;
                left: px2rem(50px);
                right: px2rem(50px);
                margin-top: -.5px;
                height: 1px;
                background-color: #dcdcdc;
            }
        }
        .swiper-container {
            padding: 0;
        }
        .swiper-slide {
            padding: 0;
            box-sizing: border-box;
            width: 25%;
            &-visible {
                &:after {
                    content: '';
                    position: absolute;
                    display: none;
                    top: 50%;
                    right: -1px;
                    margin-top: px2rem(-12px);
                    width: 1px;
                    height: px2rem(24px);
                    background-color: #dcdcdc;
                }
            }
            &.on,
            &:hover {
                .mb {
                    .icon {
                        &-default {
                            display: none;
                        }
                        &-hover {
                            display: inline-block;
                        }
                    }
                }
            }
            a {
                .pc {
                    display: none;
                }
                .mb {
                    display: block;
                    .icon {
                        width: 100%;
                        height: auto;
                        &-default {
                            display: inline-block;
                        }
                        &-hover {
                            display: none;
                        }
                    }
                }
            }
            // .icon {
            //     margin-right: px2rem(10px);
            //     width: px2rem(72px);
            //     height: px2rem(48px);
            //     line-height: px2rem(48px);
            // }
            // p {
            //     flex: 1;
            //     min-width: 0;
            //     margin-top: 0;
            //     max-height: px2rem(26px) * 3;
            //     font-size: px2rem(26px);
            //     white-space: normal;
            //     color: #3e3a39;
            //     font-weight: normal;
            //     display: -webkit-box;
            //     -webkit-line-clamp: 2;
            //     -webkit-box-orient: vertical;
            //     word-break: break-word;
            // }
        }
        &-prev,
        &-next {
            width: px2rem(50px);
            font-size: px2rem(28px);
        }
    }
}

.pro-page {
    padding: 60px 0 140px;
    .wp1340 {
        display: flex;
    }
    &-main {
        flex: 1;
        min-width: 0;
        padding-top: 52px;
    }
    @media screen and (max-width: 1200px) {
        padding-bottom: 120px;
    }
    @media screen and (max-width: 1024px) {
        padding-bottom: 100px;
    }
    @media screen and (max-width: 750px) {
        padding: 35vw 0 0;
        height: calc(100vh - .5rem);
        box-sizing: border-box;
        overflow: hidden;
        .wp1340 {
            padding: 0;
            height: 100%;
        }
        & ~ .footer-mobile {
            display: none;
        }
        &-main {
            padding: px2rem(30px) px2rem(30px) px2rem(80px);
            height: 100%;
            overflow: scroll;
            box-sizing: border-box;
        }
    }
}

.pro-sidemenu {
    padding-right: 24px;
    width: 180px;
    max-height: 100vh;
    background-color: #fff;
    box-sizing: border-box;
    &-top {
        margin-bottom: 35px;
        font-size: 14px;
        span,
        a {
            display: inline-block;
            vertical-align: top;
        }
        a {
            &:hover {
                text-decoration: underline;
            }
        }
        .title {
            color: #595757;
        }
        .iconfont {
            margin-right: 2px;
            font-size: inherit;
        }
        .reset-btn {
            position: relative;
            margin-left: 30px;
            color: #c7000b;
            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: -16px;
                margin-top: -7px;
                width: 2px;
                height: 14px;
                background-color: #e5e5e5;
            }
        }
    }
    &-list {
        li {
            border-bottom: 1px solid #e5e5e5;
            &:first-child {
                border-top: 1px solid #e5e5e5;
            }
        }
        .menu-a {
            position: relative;
            a {
                position: relative;
                display: block;
                width: 100%;
                padding: 8px 20px 8px 0;
                line-height: 22px;
                font-size: 15px;
                color: #3c3c3c;
                z-index: 2;
                backface-visibility: hidden;
                box-sizing: border-box;
                transition: all .3s;
                &:hover {
                    color: $baseColor;
                }
                &:before {
                    content: "\e612";
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    font-size: 11px;
                    color: #6f6f6f;
                    font-family: "iconfont";
                    transition: all .3s;
                }
            }
            &.on {
                a {
                    color: $baseColor;
                }
                & ~ .menu-sub {
                    display: block;
                }
            }
            .tag {
                display: inline-block;
                margin-left: 2px;
                padding: 0 6px;
                line-height: 18px;
                color: $baseColor;
                font-size: 14px;
                border: 1px solid $baseColor;
                border-radius: 4px;
            }
            &.open {
                a:before {
                    transform: translateY(-50%) rotate(-45deg);
                }
            }
        }
        .menu-sub {
            display: none;
            padding-bottom: 6px;
            a {
                position: relative;
                display: block;
                padding: 5px 20px 5px 0;
                line-height: 20px;
                font-size: 14px;
                color: #646464;
                overflow: hidden;
                box-sizing: border-box;
                transition: all .3s;
                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    width: 11px;
                    height: 11px;
                    line-height: 11px;
                    text-align: center;
                    font-size: 12px;
                    border: 1px solid #b5b5b5;
                    border-radius: 1px;
                    transition: all .3s;
                }
                &:hover {
                    color: $baseColor;
                }
                &.on {
                    &:hover {
                        color: #646464;
                    }
                    &:after {
                        content: '\e756';
                        font-family: 'iconfont';
                        color: #fff;
                        background-color: $baseColor;
                        border-color: $baseColor;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1200px) {
        &-list {
            .menu-a {
                position: relative;
                a {
                    font-size: 14px;
                    line-height: 20px;
                }
                .tag {
                    font-size: 13px;
                }
            }
            .menu-sub {
                a {
                    line-height: 18px;
                    font-size: 13px;
                }
            }
        }
    }
    @media screen and (max-width: 750px) {
        padding: 0;
        width: px2rem(215px);
        height: 100%;
        background-color: #f5f5f5;
        box-sizing: border-box;
        &-top {
            display: none;
        }
        &-scroller {
            position: relative;
            height: 100%;
            overflow: hidden;
        }
        &-list {
            li {
                border-color: #f5f5f5;
                &:first-child {
                    border-top: none;
                }
            }
            .menu-a {
                position: relative;
                a {
                    padding: px2rem(20px) px2rem(10px) px2rem(20px) px2rem(30px);
                    line-height: px2rem(38px);
                    font-size: px2rem(25px);
                    &:before {
                        display: none;
                    }
                }
                &.on {
                    & ~ .menu-sub {
                        display: block;
                    }
                }
                .tag {
                    display: none;
                }
                &.open {
                    background-color: #fff;
                    a {
                        font-size: px2rem(27px);
                        color: #2f2725;
                    }
                }
            }
            .menu-sub {
                padding-bottom: 0;
                background-color: #fff;
                a {
                    padding: px2rem(20px) px2rem(10px) px2rem(20px) px2rem(30px);
                    line-height: px2rem(38px);
                    font-size: px2rem(25px);
                    &:after {
                        display: none;
                    }
                    &:before {
                        content: '';
                        position: absolute;
                        top: px2rem(25px);
                        bottom: px2rem(30px);
                        left: px2rem(14px);
                        right: auto;
                        width: px2rem(4px);
                        height: auto;
                        background-color: $baseColor;
                        border: none;
                        opacity: 0;
                        transition: all .3s;
                    }
                    &.on {
                        color: $baseColor;
                        &:hover {
                            color: $baseColor;
                        }
                        &:before {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

.pro-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 60px;
    li {
        padding: 0 0 42px 24px;
        width: 25%;
        box-sizing: border-box;
    }
    a {
        display: block;
        text-align: center;
        background-color: #fff;
        border-radius: 4px;
        overflow: hidden;
        transition: all .3s;
        &:hover {
            box-shadow: 4px 4px 12px rgba(0, 0, 0, .1);
        }
    }
    .img {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        border-radius: 4px;
        overflow: hidden;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .info {
        padding: 20px 15px 24px;
    }
    .title,
    p {
        line-height: 24px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .title {
        font-size: 15px;
        color: #595757;
    }
    p {
        margin-top: 2px;
        font-size: 14px;
        color: #aaabab;
    }
    @media screen and (max-width: 1200px) {
        margin-bottom: 40px;
        li {
            width: (100% / 3);
        }
        .info {
            padding: 16px 15px 20px;
        }
    }
    @media screen and (max-width: 1024px) {
        margin-bottom: 30px;
        li {
            padding-bottom: 32px;
            width: 50%;
        }
        .info {
            padding: 16px 15px 20px;
        }
    }
    @media screen and (max-width: 750px) {
        margin: 0 px2rem(-10px) px2rem(20px);
        li {
            padding: 0 px2rem(10px) px2rem(50px);
            width: 50%;
        }
        a {
            &:hover {
                box-shadow: none;
                opacity: .8;
            }
        }
        .info {
            padding: px2rem(20px) 0 0;
        }
        .title,
        p {
            line-height: px2rem(32px);
        }
        .title {
            font-size: px2rem(24px);
        }
        p {
            margin-top: 2px;
            font-size: px2rem(22px)
        }
    }
}