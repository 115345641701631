.appointment-box {
    padding: 65px 0 70px;
    & + .appointment-box {
        border-top: 1px dashed #a7a8a8;
    }
    &:first-child {
        padding-top: 0;
    }
    @media screen and (max-width: 1024px) {
        padding: 60px 0 65px;
    }
    @media screen and (max-width: 750px) {
        padding: px2rem(80px) 0 px2rem(90px);
        &:last-child {
            padding-bottom: px2rem(50px);
        }
    }
}

.apponitment-title {
    margin-bottom: 35px;
    color: $baseColor;
    span {
        display: inline-block;
    }
    .cn {
        margin-right: 6px;
        font-size: 28px;
    }
    .en {
        font-size: 20px;
        text-transform: uppercase;
    }
    @media screen and (max-width: 1200px) {
        .cn {
            font-size: 26px;
        }
        .en {
            font-size: 18px;
        }
    }
    @media screen and (max-width: 1024px) {
        margin-bottom: 30px;
        .cn {
            font-size: 24px;
        }
        .en {
            font-size: 16px;
        }
    }
    @media screen and (max-width: 750px) {
        margin-bottom: 20px;
        .cn {
            font-size: 22px;
        }
        .en {
            font-size: 15px;
        }
    }
}

.apponitment-process {
    display: flex;
    padding-top: 6px;
    li {
        position: relative;
        width: 150px;
        max-width: calc((100% - 90px) / 4);
        text-align: center;
        list-style: none !important;
        &:not(:last-child) {
            margin-right: 30px;
        }
        &:not(:last-child):after {
            content: '\e773';
            position: absolute;
            right: -30px;
            top: 50%;
            transform: translateY(-50%);
            width: 30px;
            text-align: center;
            font-family: 'iconfont';
            font-size: 16px;
            color: #d2d2d2;
        }
    }
    .icon {
        height: 40px;
        line-height: 40px;
        img {
            display: inline-block;
            vertical-align: middle;
            max-height: 100%;
            max-width: 100%;
        }
    }
    p {
        margin: 20px 0 0;
        line-height: 1.4;
        font-size: 16px;
        color: #505050;
    }
    @media screen and (max-width: 1200px) {
        p {
            font-size: 15px;
        }
    }
    @media screen and (max-width: 1024px) {
        li {
            width: 130px;
            max-width: calc((100% - 60px) / 4);
            &:not(:last-child) {
                margin-right: 20px;
            }
            &:not(:last-child):after {
                right: -20px;
                width: 20px;
                font-size: 13px;
            }
        }
        .icon {
            height: 36px;
            line-height: 36px;
        }
        p {
            margin-top: 16px;
            font-size: 14px;
        }
    }
    @media screen and (max-width: 750px) {
        li {
            &:not(:last-child):after {
                font-size: 12px;
            }
        }
        .icon {
            height: 32px;
            line-height: 32px;
        }
        p {
            margin-top: 12px;
            font-size: 13px;
        }
    }
}

.service-menu {
    width: 200px;
    &.fixed {
        position: fixed;
        top: 50%;
        transform: translateY(-50%);
        z-index: 8888;
    }
    &-wp {
        order: 2;
        margin: 36px 0 0 170px;
        width: 200px;
    }
    &.no-init {
        overflow: visible;
        .swiper-wrapper {
            display: block;
            width: 100%;
        }
    }
    .swiper-wrapper {
        text-align: center;
        font-size: 0;
    }
    .swiper-slide {
        a {
            display: block;
            padding: 8px 20px;
            line-height: 24px;
            color: $baseColor;
            font-size: 15px;
            border: 1px solid $baseColor;
            background-color: #fff;
            border-radius: 42px;
            cursor: pointer;
            box-shadow: 0 px2rem(4px) px2rem(10px) rgba(0, 0, 0, .08);
            transition: all .3s;
        }
        & + .swiper-slide {
            margin-top: 30px;
        }
        &.on a,
        &:hover a{
            color: #fff;
            background-color: $baseColor;
        }
    }

    @media screen and (max-width: 1440px) {
        &-wp {
            margin-left: 120px;
        }
    }
    @media screen and (max-width: 1200px) {
        &-wp {
            margin-left: 80px;
        }
        .swiper-slide {
            a {
                padding: 6px 20px;
                line-height: 22px;
                font-size: 14px;
            }
            & + .swiper-slide {
                margin-top: 25px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        width: auto;
        margin: 0 px2rem(-20px);
        &-wp {
            margin: 0 0 80px;
            width: 100%;
        }
        &.fixed {
            position: relative;
            top: 0;
            transform: translateY(0);
            z-index: 0;
        }
        .swiper-slide {
            display: inline-block;
            vertical-align: top;
            width: auto;
            padding: 0 px2rem(20px);
            & + .swiper-slide {
                margin-top: 0;
            }
        }
    }
    @media screen and (max-width: 750px) {
        &-wp {
            margin-bottom: px2rem(90px);
        }
        .swiper-slide {
            a {
                padding: 5px px2rem(30px);
                line-height: 20px;
                font-size: 13px;
            }
        }
    }
}

.service-page {
    padding: 110px 0 100px;
    .wp1340 {
        display: flex;
    }
    @media screen and (max-width: 1200px) {
        padding: 100px 0 90px;
    }
    @media screen and (max-width: 1024px) {
        padding: 60px 0 80px;
        .wp1340 {
            display: block;
        }
    }
    @media screen and (max-width: 750px) {
        padding: px2rem(80px) 0 px2rem(110px);
    }
}

.service-detail {
    flex: 1;
    min-width: 0;
    padding-bottom: 60px;
    border-bottom: 1px dashed #cbcbcb;
    &-title {
        margin-bottom: 36px;
        padding-bottom: 32px;
        line-height: 1.5;
        font-size: 30px;
        color: #221815;
        border-bottom: 1px dashed #cbcbcb;
    }
    @media screen and (max-width: 1440px) {
        &-title {
            font-size: 28px;
        }
    }
    @media screen and (max-width: 1200px) {
        &-title {
            margin-bottom: 30px;
            font-size: 26px;
        }
    }
    @media screen and (max-width: 1024px) {
        padding-bottom: 0;
        border-bottom: none;
        &-title {
            margin-bottom: 26px;
            padding-bottom: 24px;
            font-size: 24px;
        }
    }
    @media screen and (max-width: 750px) {
        &-title {
            margin-bottom: 20px;
            padding-bottom: 18px;
            font-size: 20px;
        }
    }
}

.service-article {
    @include articleReset(14px, 28px);
    color: #727171;
    p {
        margin-bottom: 0;
    }
    @media screen and (max-width: 750px) {
        font-size: 13px;
    }
}

.text-size15 {
    font-size: 15px;
    @media screen and (max-width: 750px) {
        font-size: 14px;
    }
}

.service-article-box {
    & + .service-article-box {
        margin-top: 40px;
        @media screen and (max-width: 1024px) {
            margin-top: 30px;
        }
        @media screen and (max-width: 750px) {
            margin-top: 25px;
        }
    }
}

.text-size16 {
    font-size: 16px;
    @media screen and (max-width: 750px) {
        font-size: 15px;
    }
}

.service-article-table {
    padding: 15px 0;
    line-height: 20px;
    table {
        width: 100%;
        table-layout: fixed;
        text-align: center;
        border-collapse: collapse;
    }
    td,
    th {
        padding: 10px 26px;
        border: 1px solid #bfbfbf;
    }
    th {
        font-weight: bold;
        font-size: 15px;
    }
    .text-alignleft {
        text-align: left;
    }
    .td-span {
        display: inline-block;
        vertical-align: top;
        &:not(:last-child) {
            margin-right: 30px;
        }
    }
    @media screen and (max-width: 1024px) {
        td,
        th {
            padding: 10px 16px;
        }
        .td-span {
            &:not(:last-child) {
                margin-right: 20px;
            }
        }
    }
    @media screen and (max-width: 750px) {
        padding: 10px 0;
        line-height: 18px;
        td,
        th {
            padding: 8px px2rem(15px);
        }
        th {
            font-size: 14px;
        }
        .td-span {
            display: block;
            &:not(:last-child) {
                margin-right: 0;
            }
        }
    }
}
.install-box {
    margin-bottom: px2rem(50px);
    &:first-child {
        margin-top: -20px;
    }
    &-hd {
        cursor: pointer;
        height: 62px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 px2rem(24px);
        background-color: #c4c4c4;
        border-bottom: 1px dashed #929292;
        .t {
            color: #fff;
            font-weight: bold;
            font-size: 24px;
        }
        .arrow {
            color: #fff;
            border: 2px solid #fff;
            border-radius: 100%;
            width: 22px;
            height: 22px;
            text-align: center;
            line-height: 22px;
            font-size: 14px;
            transform: rotate(180deg);
        }
        @media (max-width: 750px) {
            .t {
                font-size: 16px;
            }
        }
    }
    &-bd {
        display: none;
    }
    &.open {
        .install-box-hd {
            background-color: rgba($baseColor, .6);
            .arrow {
                transform: rotate(0);
            }
        }
        .install-box-bd {
            display: block;
        }
    }
}
.install-list {
    display: flex;
    flex-wrap: wrap;
    margin: 20px -35px 80px;
    li {
        padding: 0 35px;
        width: 50%;
        box-sizing: border-box;
    }
    a {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 15px 0;
        border-bottom: 1px dashed #cfcfcf;
        box-sizing: border-box;
        &:hover {
            p,
            .icon-download {
                color: $baseColor;
            }
        }
    }
    .icon-pdf {
        flex: 0 0 40px;
        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }
    p {
        margin: 0 10px 0 14px;
        line-height: 24px;
        font-size: 15px;
        color: #727171;
        transition: all .3s;
    }
    .icon-download {
        font-size: 14px;
        color: #898989;
        transition: all .3s;
    }
    @media screen and (max-width: 1200px) {
        margin: 20px -25px 70px;
        li {
            padding: 0 25px;
        }
        .icon-pdf {
            flex: 0 0 35px;
        }
        p {
            font-size: 14px;
        }
        .icon-download {
            font-size: 13px;
        }
    }
    @media screen and (max-width: 1024px) {
        display: block;
        margin: 10px 0 60px;
        li {
            padding: 0;
            width: 100%;
        }
        p {
            flex: 1;
            min-width: 0;
        }
        .icon-download {
            color: #c9caca;
        }
    }
    @media screen and (max-width: 750px) {
        margin-bottom: 0;
        a {
            padding: 12px 0;
        }
        .icon-pdf {
            flex: 0 0 25px;
        }
        p {
            flex: 1;
            min-width: 0;
            margin-left: 10px;
        }
    }
}

.install-video {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -13px 30px;
    li {
        padding: 0 13px 45px;
        width: (100% / 3);
        box-sizing: border-box;
    }
    .box {
        height: 100%;
        background-color: #fff;
        box-sizing: border-box;
        cursor: pointer;
        transition: all .3s;
        &:hover {
            box-shadow: 0 px2rem(4px) px2rem(12px) rgba(0, 0, 0, .15);
            .img {
                &:before {
                    opacity: 1;
                }
            }
        }
    }
    .img {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: (243 / 428) * 100%;
        overflow: hidden;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .37);
            opacity: 0;
            z-index: 2;
            transition: all .3s;
        }
        &-play {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 54px;
            height: 54px;
            background: url(../images/icon81.png) center center no-repeat;
            background-size: contain;
            z-index: 6;
        }
    }
    .title {
        padding: 15px 18px 25px;
        line-height: 24px;
        font-size: 15px;
        color: #727171;
    }
    @media screen and (max-width: 1200px) {
        margin-bottom: 20px;
        .title {
            padding-bottom: 20px;
        }
    }
    @media screen and (max-width: 1024px) {
        li {
            width: 50%;
        }
    }
    @media screen and (max-width: 750px) {
        display: block;
        margin: 0 0 px2rem(30px);
        li {
            padding: 0 0 px2rem(50px);
            width: 100%;
        }
        .img {
            &-play {
                width: 45px;
                height: 45px;
            }
        }
        .title {
            padding: 12px px2rem(30px) 15px;
            font-size: 14px;
        }
    }
}

.upkeep-list {
    display: flex;
    flex-wrap: wrap;
    margin: -20px -35px 80px;
    li {
        padding: 0 35px;
        width: 50%;
        box-sizing: border-box;
    }
    a {
        display: block;
        height: 100%;
        padding: 24px 10px;
        line-height: 24px;
        font-size: 15px;
        color: #727171;
        border-bottom: 1px dashed #cfcfcf;
        box-sizing: border-box;
        transition: all .3s;
        &:hover {
            color: $baseColor;
        }
    }
    .tag {
        display: inline-block;
        vertical-align: middle;
        margin: -3px 0 0 5px;
        padding: 0 4px;
        line-height: 18px;
        font-size: 14px;
        border-width: 1px;
        border-style: solid;
        border-radius: 2px;
        &-new {
            color: $baseColor;
            border-color: $baseColor;
        }
        &-perfect {
            color: #b38952;
            border-color: #b38952;
        }
    }
    @media screen and (max-width: 1200px) {
        margin: -20px -25px 70px;
        li {
            padding: 0 25px;
        }
        a {
            padding: 22px 0;
            font-size: 14px;
        }
        .tag {
            margin-left: 4px;
            line-height: 16px;
            font-size: 12px;
        }
    }
    @media screen and (max-width: 1024px) {
        display: block;
        margin: -10px 0 60px;
        li {
            padding: 0;
            width: 100%;
        }
    }
    @media screen and (max-width: 750px) {
        margin-bottom: px2rem(90px);
        li {
            padding: 0;
            width: 100%;
        }
        a {
            padding: 14px 0;
        }
    }
}


.upkeep-page {
    .news-title {
        padding-right: 330px;
        @media screen and (max-width: 1440px) {
            padding-right: 310px;
        }
        @media screen and (max-width: 1200px) {
            padding-right: 290px;
        }
        @media screen and (max-width: 1024px) {
            padding-right: 0;
        }
    }
}

.upkeep-detail {
    display: flex;
    .news-article {
        flex: 1;
        min-width: 0;
    }
    &-right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-left: 60px;
        padding-left: 60px;
        width: 210px;
        border-left: 1px dashed #cbcbcb;
    }
    @media screen and (max-width: 1440px) {
        &-right {
            width: 190px;
        }
    }
    @media screen and (max-width: 1200px) {
        &-right {
            margin-left: 50px;
            padding-left: 50px;
        }
    }
    @media screen and (max-width: 1024px) {
        display: block;
        &-right {
            display: block;
            margin-left: 0;
            padding-left: 0;
            width: 100%;
            border-left: none;
        }
    }
}

.help-evaluate {
    text-align: center;
    .title {
        font-size: 15px;
        color: #595757;
    }
    &-list {
        margin-top: 20px;
        font-size: 0;
        li {
            display: inline-block;
            vertical-align: top;
            width: 85px;
            height: 46px;
            line-height: 46px;
            font-size: 15px;
            color: #fff;
            background-color: #ee9545;
            border-radius: 46px;
            cursor: pointer;
            transition: all .3s;
            & + li {
                margin-left: 15px;
            }
        }
    }
    &.choose-yes {
        li {
            color: #fff;
            cursor: default;
        }
        .btn-yes {
            background-color: $baseColor;
            &:before {
                content: '\e65e';
                font-family: 'iconfont';
                margin-right: 5px;
            }
        }
        .btn-no {
            background-color: #ddd;
        }
    }
    &.choose-no {
        .title,
        .help-evaluate-list {
            display: none;
        }
        .help-consult {
            display: block;
        }
    }
    @media screen and (max-width: 1024px) {
        padding: 40px 0 15px;
        .title {
            font-size: 14px;
        }
        &-list {
            li {
                margin: 0 10px;
                height: 40px;
                line-height: 40px;
                font-size: 14px;
                & + li {
                    margin-left: 10px
                }
            }
        }
        &.choose-no {
            .help-consult {
                display: flex;
            }
        }
    }
}

.help-consult {
    display: none;
    width: 180px;
    text-align: left;
    a {
        display: flex;
        align-items: center;
        padding: 18px 12px 18px 14px;
        line-height: 22px;
        border: 1px solid #e6e6e6;
        border-radius: 4px;
        transition: all .3s;
        &:hover {
            border-color: $baseColor;
            box-shadow: 0 0 px2rem(10px) rgba(0, 172, 235, .2);
        }
        & + a {
            margin-top: 15px;
        }
    }
    .icon {
        margin-right: 12px;
        font-size: 36px;
        color: #727171;
    }
    p {
        font-size: 16px;
        color: #949495;
    }
    .span-blue {
        font-size: 14px;
        color: $baseColor;
    }
    @media screen and (max-width: 1024px) {
        justify-content: center;
        width: auto;
        a {
            margin: 0 10px;
            width: 150px;
            padding: 15px 12px 15px 14px;
            line-height: 20px;
            & + a {
                margin-top: 0;
            }
        }
        .icon {
            font-size: 34px;
        }
        p {
            font-size: 15px;
        }
        .span-blue {
            font-size: 13px;
        }
    }
    @media screen and (max-width: 750px) {
        margin: 0 px2rem(-15px);
        a {
            margin: 0 px2rem(15px);
            width: 120px;
            padding: 10px;
            line-height: 18px;
            & + a {
                margin-top: 0;
            }
        }
        .icon {
            margin-right: 8px;
            font-size: 28px;
        }
        p {
            font-size: 13px;
        }
        .span-blue {
            font-size: 12px;
        }
    }
}

.feedback-page {
    padding: 90px 0 120px;
    @media screen and (max-width: 1024px) {
        padding: 80px 0 110px;
    }
    @media screen and (max-width: 750px) {
        padding: px2rem(100px) 0 px2rem(110px);
    }
}

.contact-map {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: (855 / 1920) * 100%;
    &-box {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    @media screen and (max-width: 750px) {
        margin: px2rem(80px) px2rem(30px) 0;
        width: auto;
        padding-bottom: 0;
        height: px2rem(600px);
    }
}

.contact-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -55px;
    li {
        width: 50%;
        padding: 0 55px;
        box-sizing: border-box;
        &:nth-child(n+3) {
            .box {
                border-top: 1px solid #cbcbcb;
            }
        }
        .box {
            padding: 70px 70px 60px;
            height: 100%;
            background-color: #fff;
            transition: all .4s;
            box-sizing: border-box;
            &:hover,
            &.on {
                border-top-color: transparent;
                .box-logo {
                    &:after {
                        background-color: $baseColor;
                    }
                    &-default {
                        display: none;
                    }
                    &-active {
                        display: inline-block;
                    }
                }
                .title,
                .p-icon,
                .p p {
                    color: $baseColor;
                }
            }
            &:hover {
                position: relative;
                height: calc(100% + 1px);
                box-shadow: px2rem(6px) px2rem(6px) px2rem(16px) #e7e7e7;
            }
            &-logo {
                margin-bottom: 22px;
                &:after {
                    content: '';
                    display: block;
                    margin-top: 28px;
                    width: 32px;
                    height: 1px;
                    background-color: #cbcbcb;
                    transition: all .3s;
                }
                img {
                    max-width: 100%;
                    max-height: 27px;
                }
                &-default {
                    display: inline-block;
                }
                &-active {
                    display: none;
                }
            }
        }
    }
    .title {
        margin-bottom: 10px;
        line-height: 1.5;
        font-size: 22px;
        color: #464646;
        transition: all .3s;
    }
    .p {
        display: flex;
        line-height: 36px;
        &-icon {
            margin-right: 8px;
            font-size: 18px;
            color: #4a4a4a;
            transition: all .3s;
        }
        p {
            flex: 1;
            min-width: 0;
            font-size: 15px;
            color: #464646;
            transition: all .3s;
        }
    }
    @media screen and (max-width: 1440px) {
        margin: 0 -40px;
        li {
            padding: 0 40px;
            .box {
                padding: 50px 50px 40px;
                &-logo {
                    img {
                        max-height: 24px;
                    }
                }
            }
        }
        .title {
            font-size: 22px;
        }
        .p {
            line-height: 32px;
            &-icon {
                font-size: 16px;
            }
            p {
                font-size: 14px;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        margin: 0 -20px;
        li {
            padding: 0 20px;
            .box {
                padding: 40px 40px 30px;
                &-logo {
                    img {
                        max-height: 22px;
                    }
                }
            }
        }
        .title {
            font-size: 20px;
        }
    }
    @media screen and (max-width: 1024px) {
        display: block;
        margin: -40px 0 0;
        li {
            width: 100%;
            padding: 0;
            &:nth-child(n+2) {
                .box {
                    border-top: 1px solid #cbcbcb;
                }
            }
            .box {
                padding: 40px 0 35px;
                &:hover {
                    box-shadow: none;
                }
            }
        }
    }
    @media screen and (max-width: 750px) {
        margin-top: -30px;
        li {
            .box {
                padding: 30px 0 26px;
                &-logo {
                    margin-bottom: 15px;
                    &:after {
                        margin-top: 20px;
                        width: 20px;
                    }
                    img {
                        max-height: 16px;
                    }
                }
            }
        }
        .title {
            margin-bottom: 5px;
            font-size: 18px;
        }
        .p {
            line-height: 28px;
            &-icon {
                margin-right: 5px;
                font-size: 14px;
            }
            p {
                font-size: 13px;
            }
        }
    }
}